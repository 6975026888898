import React, { useState } from 'react';
import './SaboresInicio.css';
import { useNavigate } from 'react-router-dom';
import {AudioButton} from '../components/AudioButton'

export const SaboresInicio = () => {
    const pathAudio = '/assets/Audios/sabores/sabores_presentacion.mp3';
    const navigate = useNavigate();
    const [shouldStop, setShouldStop] = useState(false);

    const onClick = () => {
        setShouldStop(true);
        
        setTimeout(() => {
            navigate('/saboresCooler')
          }, 100); // Puedes ajustar este tiempo si es necesario
    }
    return (
        <div className='container'>
        <div className="row mt-3">
            <div className='btn-audio-sabores'>
                <AudioButton pathAudio={pathAudio} />
            </div>
        </div>
        <div className="row">
            <div className='generalContainderSabores'>
                <div className="containerInicioSabores">
                    <div className="image-containerSabores">
                        
                    </div>
                    <div className="buttons-containerSabores">
                        <div className="top-buttonSabores"></div>
                        <button className="bottom-buttonSabores" onClick={onClick}></button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}
