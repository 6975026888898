import './AudioButton.css'
import React, {useEffect, useRef, useState} from 'react';

let audio = new Audio()

export const AudioButton = ({pathAudio = "", shouldStop = false}) => {

  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  // Manejo del ciclo de vida del audio cuando se inicia la reproducción
  useEffect(() => {
    const audioElement = audioRef.current;

    if (audioElement) {
      audioElement.onended = () => setIsPlaying(false);
    }

    // Cleanup para evitar fugas de memoria
    return () => {
      if (audioElement) {
        audioElement.onended = null;
      }
    };
  }, []);

   // Efecto que se ejecuta cuando shouldStop cambia a true
   useEffect(() => {
    const audioElement = audioRef.current;

    if (shouldStop && isPlaying && audioElement) {
      audioElement.pause();        // Detiene el audio
      audioElement.currentTime = 0; // Reinicia el audio al principio
      setIsPlaying(false);         // Actualiza el estado
    }
  }, [shouldStop, isPlaying]); // Se ejecuta cuando shouldStop o isPlaying cambian

  // Función para reproducir el audio
  const playAudio = () => {
    const audioElement = audioRef.current;

    if (audioElement) {
      setIsPlaying(true);
      audioElement.play();
    }
  };

  // Efecto que se ejecuta cuando shouldStop cambia
  useEffect(() => {
    const audioElement = audioRef.current;

    if (shouldStop && isPlaying && audioElement) {
      audioElement.pause();      // Detiene el audio
      audioElement.src = "";
      setIsPlaying(false);       // Actualiza el estado
    }
  }, [shouldStop, isPlaying]); // Se ejecuta cuando shouldStop o isPlaying cambian

  return (
    <>
      <audio ref={audioRef} src={pathAudio}></audio>
      <button
        className={`audioButton  ${+ (isPlaying) ? 'filter-brightness' : ''}`}
        disabled={isPlaying}
        onClick={playAudio}
      >
      </button>
    </>
  );
}