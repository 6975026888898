import { useDispatch } from "react-redux";

import { startCheckTestDone, startSetTestScore } from "../../store/ui/thunks";

import './Score.css';

export const Score = ({score, id}) => {
    const dispatch = useDispatch();
    const finalScore = score;

    const onClick = () => {
        dispatch(startSetTestScore(null))
        dispatch(startCheckTestDone(id))
    }
  return (
    <div className="row">
        <div className="col-md-12 height-100vh">
            <div className='center-div'>
                <div className="score-container margin-auto">
                    <p className='title-punctuation'></p>
                    <p className='body-punctuation'>{finalScore} / 10</p>
                </div>
                <div className="score-buttons-container">
                    <button className="score-top-button" onClick={onClick}><p className='menu-txt mb-2'></p></button>
                </div>
            </div>
        </div>
    </div>
  )
}
