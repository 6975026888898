import API from "../api/api";

    // La URL de interacion se usa en cada actividad donde se manda las respuestas del acomodo de productos (paquetes) y de comunicacion (letreros de promociones)
    // private string urlInteraccionDesarrollo = "https://dev-api.app.coachvirtual.info/coach-virtual/v1/actividades/interacciones";
    // private string urlInteraccionQA = "https://qa-api.app.coachvirtual.info/coach-virtual/v1/actividades/interacciones";
    // private string urlInteraccionProduccion = "https://api.app.coachvirtual.info/coach-virtual/v1/actividades/interacciones";
    // Ejemplo de lo que se manda {"idUsuario":"28","idActividad":"1","idPlataforma":"1","idInteraccion":"1","puntos":"0","fechaHoraOperacion":"2023-10-06 09:36:11","ubicaciones":[{"idUbicacion":"1","idItem":"1","puntos":"0","correcto":"0"},{"idUbicacion":"2","idItem":"2","puntos":"0","correcto":"0"},{"idUbicacion":"3","idItem":"3","puntos":"0","correcto":"0"},{"idUbicacion":"4","idItem":"4","puntos":"0","correcto":"0"}]}
    // idActividad: Comidas: 1 / MisMomentos: 2 / Hidratacion: 3 / Desayuno: 4
    // idPlataforma: Rack: 1 / Cooler: 2 / Tortillero: 3
    // idInteraccion: Acomodo Paquetes: 1 / Acomodo Comunicacion: 2

    //------------------------------------------------------------------------------------------------------------------------

    // La URL de login es solo para mandar el login
    // private string urlLoginDesarrollo = "https://dev-api.app.coachvirtual.info/coach-virtual/v1/auth/login/";
    // private string urlLoginQA = "https://qa-api.app.coachvirtual.info/coach-virtual/v1/auth/login/";
    // private string urlLoginProduccion = "https://api.app.coachvirtual.info/coach-virtual/v1/auth/login/";
    // Ejemplo de lo que se manda {"numeroEmpleado":"12345678","password":"Pass1234"}

    //------------------------------------------------------------------------------------------------------------------------

    // La URL de Registo solo se usa en el registro
    // private string urlRegisterDesarrollo = "https://dev-api.app.coachvirtual.info/coach-virtual/v1/auth/registro";
    // private string urlRegisterQA = "https://qa-api.app.coachvirtual.info/coach-virtual/v1/auth/registro";
    // private string urlRegisterProduccion = "https://api.app.coachvirtual.info/coach-virtual/v1/auth/registro";
    // Ejemplo de lo que se manda {"idPuesto":2,"idZona":4,"numeroEmpleado":"96325874","password":"Pass1234","nombre":"Poiuy","apellidoPaterno":"Lkjhg","apellidoMaterno":"Mnbvc"}

    //------------------------------------------------------------------------------------------------------------------------

    // La URL de Cuestionario se usa en los dos cuestionarios, de inicio y final 
    // private string urlCuestionarioDesarrollo = "https://dev-api.app.coachvirtual.info/coach-virtual/v1/evaluaciones/respuestas";
    // private string urlCuestionarioQA = "https://qa-api.app.coachvirtual.info/coach-virtual/v1/evaluaciones/respuestas";
    // private string urlCuestionarioProduccion = "https://api.app.coachvirtual.info/coach-virtual/v1/evaluaciones/respuestas";
    // Ejemplo de los que se manda {"idUsuario":28,"tipo":1,"puntos":10.0,"respuestas":[{"idPregunta":1,"respuesta":"A","correcta":"S","puntos":2.0},{"idPregunta":2,"respuesta":"B","correcta":"S","puntos":2.0},{"idPregunta":3,"respuesta":"A","correcta":"S","puntos":2.0},{"idPregunta":4,"respuesta":"C","correcta":"S","puntos":2.0},{"idPregunta":5,"respuesta":"A","correcta":"S","puntos":2.0}]}
    // tipo: Cuestionario Inicio: 1 / Cuestionario Final: 2

    //------------------------------------------------------------------------------------------------------------------------

    // La URL de cuestionario Contestada es para mandar que el isuario ya hizo el cuestionario tanto de inicio como de final 
    // private string urlCuestionarioContestadaDesarrollo = "https://dev-api.app.coachvirtual.info/coach-virtual/v1/evaluaciones";
    // private string urlCuestionarioContestadaQA = "https://qa-api.app.coachvirtual.info/coach-virtual/v1/evaluaciones";
    // private string urlCuestionarioContestadaProduccion = "https://api.app.coachvirtual.info/coach-virtual/v1/evaluaciones";
    // Ejemplo de lo que se manda {"idUsuario":28,"tipo":1,"estatus":"C"}
    // tipo: Cuestionario Inicio: 1 / Cuestionario Final: 2

    //------------------------------------------------------------------------------------------------------------------------

    // Se utiliza para saber el status de los cuestionarios al final se manda el ID de usuario Ej: v1/evaluaciones/estatus/25
    // private string urlCuestionarioGetStatusDesarrollo = "https://dev-api.app.coachvirtual.info/coach-virtual/v1/evaluaciones/estatus/";
    // private string urlCuestionarioGetStatusQA = "https://qa-api.app.coachvirtual.info/coach-virtual/v1/evaluaciones/estatus/";
    // private string urlCuestionarioGetStatusProduccion = "https://api.app.coachvirtual.info/coach-virtual/v1/evaluaciones/estatus/";
    // Ejemplo de lo que returna de respuesta {"mensaje":"La operación fue exitosa.","respuesta":[{"idTipoEvaluacion":1,"nombreEvaluacion":"Evaluacion inicial","puntos":10,"estatus":"C","fechaHoraOperacion":"2023-10-06 10:07:41"},{"idTipoEvaluacion":2,"nombreEvaluacion":"Evaluacion final","puntos":0,"estatus":"","fechaHoraOperacion":""}]}
    //------------------------------------------------------------------------------------------------------------------------

    // Se ocupa para Enviar el status de la actividad y para obtener la inf  del Usuario 
    // En las siguientes variables se agrega al fina el ID del empleado para retornar su info Ej: /actividades/estatus/idUsuario ( /actividades/estatus/25 )
    // private string urlEstatusactDesarrollo = "https://dev-api.app.coachvirtual.info/coach-virtual/v1/actividades/estatus";
    // private string urlEstatusactQA = "https://qa-api.app.coachvirtual.info/coach-virtual/v1/actividades/estatus";
    // private string urlEstatusactProduccion = "https://api.app.coachvirtual.info/coach-virtual/v1/actividades/estatus";
    // Ejemplo de lo que se manda para el status de la act Json estatus: {"idUsuario":28,"idActividad":1,"estatus":"T"}
    // estatus: Pendiente: P / Terminada: T
    // Ejemplo de lo que reguresa cuando se manda con el ID del empleado {"mensaje":"La operación fue exitosa.","respuesta":[{"idTipoEvaluacion":1,"nombreEvaluacion":"Evaluacion inicial","puntos":10,"estatus":"C","fechaHoraOperacion":"2023-10-06 10:07:41"},{"idTipoEvaluacion":2,"nombreEvaluacion":"Evaluacion final","puntos":0,"estatus":"","fechaHoraOperacion":""}]}



/* BEGIN AUTH*/
export const loginWithEmployeeNumberPassword = async ({ employeeNumber, password}) => {
    try {
        const json = { numeroEmpleado: employeeNumber, password}
        const result = await API.post('auth/login/', json)

        localStorage.setItem('user', JSON.stringify(result.data.respuesta));
        return {
            ok: true,
            ...result
        }
    } catch (error) {
        const result = error.response;
        return {
            ok: false,
            ...result
        }
    }
}

export const registerUser = async ({ id,
    employeeNumber,
    position,
    name,
    middleName,
    lastName,
    zone, password}) => {
        
    try {
        const json = {
            numeroEmpleado: employeeNumber,
            idPuesto: position,
            nombre: name,
            apellidoMaterno: lastName,
            apellidoPaterno: middleName,
            idZona: zone,
            password}
        const result = await API.post('auth/registro/', json)

        return {
            ok: true,
            ...result
        }
    } catch (error) {
        const result = error.response;
        return {
            ok: false,
            ...result
        }
    }
}

/* END AUTH*/

/**BEGIN UI */
export const getActivitiesInformation = async (id) => {
    try {
        const result = await API.get(`actividades/estatus/${id}`);

        return {
            ok: true,
            ...result
        }
    } catch (error) {
        const result = error.response;
        return {
            ok: false,
            ...result
        }
    }
}

export const registerActivityDone = async (json) => {
    try {
        const result = await API.post(`actividades/estatus`, json);

        return {
            ok: true,
            ...result
        }
    } catch (error) {
        const result = error.response;
        return {
            ok: false,
            ...result
        }
    }
}

export const getTestInformation = async (id) => {
    try {
        const result = await API.get(`evaluaciones/estatus/${id}`);

        return {
            ok: true,
            ...result
        }
    } catch (error) {
        const result = error.response;
        return {
            ok: false,
            ...result
        }
    }
}

export const registerTest = async (json) => {
    try {

        const firstJson = {
            "idUsuario": json.idUsuario,
            "tipo":json.tipo,
            "estatus":"C"
        };
        await API.post(`evaluaciones`, firstJson);
        const result = await API.post(`evaluaciones/respuestas`, json);

        return {
            ok: true,
            ...result
        }
    } catch (error) {
        const result = error.response;
        return {
            ok: false,
            ...result
        }
    }
}

export const registerActivity = async (json) => {
    try {
        const result = await API.post(`actividades/interacciones`, json);

        return {
            ok: true,
            ...result
        }
    } catch (error) {
        const result = error.response;
        return {
            ok: false,
            ...result
        }
    }
}
/**END UI */