import { loginWithEmployeeNumberPassword, registerUser } from "../../api/providers";
import { changeStatusRegister, checkingCredencials, login, logout, register } from "./authSlice"
import { setUserInfo } from '../ui/uiSlice'

export const checkingAuthentication = () => {
    return async (dispatch) => {
        dispatch(checkingCredencials());
    }
}

export const startLoginWithEmployeeNumberPassword = ({ employeeNumber, password}) => {
    return async (dispatch) => {
        dispatch(checkingCredencials());
        const {ok, data } = await loginWithEmployeeNumberPassword({ employeeNumber, password});

        
        if( !ok ){
            localStorage.removeItem('user');
            return dispatch(logout(data.mensaje));
        } 

        const { apellidoMaterno, apellidoPaterno,
            idPuesto,idUsuario, idZona, nombre , numeroEmpleado } = data.respuesta;

        const json = {
            id: idUsuario,
            employeeNumber: numeroEmpleado,
            positionId: idPuesto,
            name: nombre,
            middleName: apellidoMaterno,
            lastName: apellidoPaterno,
            zoneId: idZona,
        }

        setTimeout(() => {
            dispatch(setUserInfo(json));
            dispatch(login(json));
        }, 100) 
    }
}

export const startRegister = ({
    employeeNumber,
    position,
    name,
    middleName,
    lastName,
    zone, 
    password}, errorMessage) => {
    return async (dispatch) => {

        if(errorMessage != null){
            dispatch(logout(null))
        }

        dispatch(checkingCredencials());
        const {ok, data } = await registerUser({ 
            employeeNumber,
            position,
            name,
            middleName,
            lastName,
            zone,
            password});
        if( !ok ){
            localStorage.removeItem('user');
            let mensaje = (data.mensaje === 'El usuario ya existe.') ? 'EL USUARIO YA EXISTE' : 'OCURRIO UN PROBLEMA VUELVE a INTENTARLO';
            return dispatch(logout(mensaje));
        } 

        dispatch(register());
    }
}

export const startchangeRegisterStatus = () => {
    return async (dispatch) => {
        dispatch(checkingCredencials());

        dispatch(changeStatusRegister());
    }
}

export const startLogout = () => {
    return async (dispatch) => {
        localStorage.removeItem('user');
        return dispatch(logout(null));
    }
}