import { useSelector } from "react-redux";

export const AnswerComponent = ({description, id, event, idSection}) => {
  const { testAnswers = [] } = useSelector( state => state.ui);

  let result = [];
  

for(let i in testAnswers)
    result.push(testAnswers[i]);

  const elementExist = result.filter((element ) => {
    return element.id === id && element.idSection === idSection
  })

  let className = "";
  if (elementExist.length > 0) {
    className = "filter-brightness";
  }

  return (
    <li className={`list-group-item ${className}`}>
        <button className='list-group-item-buttom' onClick={(e) => {event(e,id, idSection)}}><strong>{description}</strong></button>
    </li>
  )
}
