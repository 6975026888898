import React from 'react';
import './EleccionBotonesInicio.css';
import { useNavigate } from 'react-router-dom';

export const EleccionBotonesInicio = () => {
  const navigate = useNavigate();

  const onClick = (path) => {
    navigate(path)
  }

  return (
    <>
   <div className="container">
  <div className="row d-flex align-items-center align-content-center heigh-100vh">
    <div className="col-md-12 mb-5 d-flex justify-content-center">
      <button className="sabores-btn-fresca sabores-btn-eleccion" onClick={() => onClick("saboresFresca")}>Fresca</button>
    </div>
    <div className="col-md-12 d-flex justify-content-center">
      <button className="sabores-btn-sidral sabores-btn-eleccion" onClick={() => onClick("saboresSidral")}>Sidral</button>
    </div>
  </div>
</div>
    </>
  )
}


