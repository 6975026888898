import React, { useState } from 'react';
import './DesayunoInicio.css';
import {AudioButton} from './AudioButton'
import { useNavigate } from 'react-router-dom';

const DesayunoPInicio = () => {
    const pathAudio = '/assets/Audios/desayuno/desayuno_presentacion.mp3';
    const navigate = useNavigate();
    const [shouldStop, setShouldStop] = useState(false);

    const onClick = () => {
        setShouldStop(true);

        setTimeout(() => {
            navigate('/desayunoCooler')
          }, 100); // Puedes ajustar este tiempo si es necesario
    }

    return (
        <div className='container'>
            <div className="row mt-3">
                <div className='btn-audio-desayuno'>
                <AudioButton pathAudio={pathAudio} shouldStop={shouldStop}/>
                </div>
            </div>
            <div className="row">
                <div className='generalContainderDesayuno'>
                    <div className="containerInicioDesayuno">
                        <div className="image-containerDesayuno">
                            
                        </div>
                        <div className="buttons-containerDesayuno">
                            <div className="top-buttonDesayuno"></div>
                            <button className="bottom-buttonDesayuno" onClick={onClick}></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DesayunoPInicio;
