import { useDispatch, useSelector } from "react-redux";
import { AudioButton } from "../../components/AudioButton"
import { SliderList } from "../../components/SliderList";
import { UILayout } from "../../layout/UILayout"

import './DesayunoRack.css';
import { startActiveImage, startRegisterActivity, startSetError, startStoreImageSelected } from "../../../store/ui/thunks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "../../../globalcomponents/Alert";
import { desayunoRackPackages } from '../../../json/jsonConst';

export const DesayunoRack = () => {
    const pathAudio = '/assets/Audios/desayuno/desayuno_rack_productos.mp3';

    const navigate = useNavigate();
    const dispatch  = useDispatch();
    const { activeImage, imagesSelected = [], id,
        errorMessage, firstActivityScore } = useSelector( state => state.ui);
    const [isImageSelected, setIsImageSelected] = useState(false);
    const [classDisplay, setClassDisplay] = useState("d-none");
    const [classDisplayForm, setClassDisplayForm] = useState("desayuno-rack-background-transparent");
    const [classDisplaySliderMobileShow, setClassDisplaySliderMobileShow] = useState("");
    const [classDisplayCoolerMobileHide, setClassDisplayCoolerMobileHide] = useState("");
    const [parrillaNumber, setParrillaNumber] = useState(0)
    const [showMessage, setShowMessage] = useState(false)
    const [textAlert, setTextAlert] = useState("")
    const [ok, setOk] = useState(false)
    const [trigger, setTrigger] = useState(0);
    const [shouldStop, setShouldStop] = useState(false);

    let pathImage = "";
    let nameImage = "";

    if(!!activeImage.path){
        nameImage = activeImage.name;
        pathImage = `${activeImage.path}${nameImage}`;
    }

    const onShowParrilla = () => {
        setClassDisplay("");
        setClassDisplayForm("");
        setClassDisplaySliderMobileShow("desayuno-slider-mobile")
        setClassDisplayCoolerMobileHide("desayuno-image-mobile")
        setIsImageSelected(false);
        dispatch(startActiveImage(null, null, null, null)); 
    }

    const onHideParrilla = () => {
        hideParrilla();
    }    

    const onSavePackage = () => {
        hideParrilla();
        dispatch(startStoreImageSelected(activeImage)); 
    }

    const hideParrilla = () => {
        setClassDisplaySliderMobileShow("")
        setClassDisplayCoolerMobileHide("")
        setClassDisplay("d-none");
        setClassDisplayForm("desayuno-rack-background-transparent")
    }

    const onFocus = (number) => {
        setParrillaNumber(number);
    }

    const onExecute = async () => {
        dispatch(startSetError(errorMessage))
        setTrigger(prev => prev + 1);
        const json = getJson();
        let errorMessageUpdate = null;
        errorMessageUpdate = await dispatch(startRegisterActivity(json, errorMessage,1));

        if(firstActivityScore === null && errorMessageUpdate !== null){
            setTextAlert("OCURRIO UN ERROR");
            setOk(false);
            setShowMessage(true);
            return;
        }

        setOk(true);
        setTextAlert("GUARDADO");
        setShowMessage(true);
        setShouldStop(true);
        setTimeout(() => {
            navigate('/desayunoRackComunicacion');    
        }, 1000);
    }

    const getJson = () => {
        let score = 0;

        const fechaHoraActual = new Date();
        const  valueScore= 17.5;

        // Obten los componentes de la fecha y la hora
        const año = fechaHoraActual.getFullYear();
        const mes = String(fechaHoraActual.getMonth() + 1).padStart(2, '0');
        const dia = String(fechaHoraActual.getDate()).padStart(2, '0');
        const horas = String(fechaHoraActual.getHours()).padStart(2, '0');
        const minutos = String(fechaHoraActual.getMinutes()).padStart(2, '0');
        const segundos = String(fechaHoraActual.getSeconds()).padStart(2, '0');

        // Formatea la fecha y la hora en el formato deseado
        const fechaHoraFormateada = `${año}-${mes}-${dia} ${horas}:${minutos}:${segundos}`;


        let json = {
            "idUsuario":id,
            "idActividad":"4",
            "idPlataforma":"1",
            "idInteraccion":"1",
            "fechaHoraOperacion": fechaHoraFormateada,
            "ubicaciones": []
        };

        imagesSelected.map((element) => {
            let productScore = 0;
            let correct = 0;
            
            switch (element.parrillaNumber) {
                case 1:
                    if(element.id ===2){
                        productScore = valueScore;
                        score += productScore;
                        correct = 1;
                    }
                    break;
                case 2:
                    if(element.id === 3){
                        productScore = valueScore;
                        score += productScore;
                        correct = 1;
                    }
                    break;
                case 3:
                    if(element.id === 6){
                        productScore = valueScore;
                        score += productScore;
                        correct = 1;
                    }
                    break;
                default:
                    if(element.id === 9){
                        productScore = valueScore;
                        score += productScore;
                        correct = 1;
                    }
                    break;
            }

            json.ubicaciones.push({
                "idUbicacion" : element.parrillaNumber,
                "idItem" : element.id,
                "correcto": correct,
                "puntos" : productScore
            }
            );
        });

        json.puntos = score;

        return json;
    }

    useEffect(() => {
        if(!!activeImage.path){
            setIsImageSelected(true);
        }
        dispatch(startSetError(errorMessage))
    }, [activeImage.path])

    useEffect(() => {
        window.location.hash="No-back-button";
       window.location.hash="Again-No-back-button" //chrome
       window.onhashchange=function(){window.location.hash="No-back-button";}
    }, [])

  return (
    <>
    <UILayout>
        <div className="row mt-3">
            <div className="col-md-6 col">
            <AudioButton pathAudio={pathAudio} shouldStop={shouldStop} />
            </div>
            <div className="col-md-6 col">
                <div className="float-right">
                <button disabled={ imagesSelected.length < 4}  onClick={onExecute} type='button' className='start-btn-registrar'><p className=' start-txt-registrar'></p></button>
                </div>
            </div>
        </div>
        <div className="row desayuno-rack-margin-top">
            <div className="col-md-6">
                <div className={`desayuno-image ${classDisplayCoolerMobileHide}`}>
                    <button className={`desayuno-btn oneBtn ${parrillaNumber === 1 && 'desayuno-btn-selected'}`} onClick={() => onFocus(1)}>
                        { imagesSelected.map((element, key) => {
                            if(element.parrillaNumber === 1){
                                const fullPath = `${element.path}${element.name}`;
                                return <img key={key} src={fullPath} alt={element.name} />
                            }
                        })}
                        
                    </button>
                    <button className={`desayuno-btn twoBtn ${parrillaNumber === 2 && 'desayuno-btn-selected'}`} onClick={() => onFocus(2)}>
                        { imagesSelected.map((element, key) => {
                            if(element.parrillaNumber === 2){
                                const fullPath = `${element.path}${element.name}`;
                                return <img key={key} src={fullPath} alt={element.name} />
                            }
                        })} 
                    </button>
                    <button className={`desayuno-btn threeBtn ${parrillaNumber === 3 && 'desayuno-btn-selected'}`} onClick={() => onFocus(3)}>
                        { imagesSelected.map((element, key) => {
                            if(element.parrillaNumber === 3){
                                const fullPath = `${element.path}${element.name}`;
                                return <img key={key} src={fullPath} alt={element.name} />
                            }
                        })}
                    </button>
                    <button className={`desayuno-btn fourBtn ${parrillaNumber === 4 && 'desayuno-btn-selected'}`} onClick={() => onFocus(4)}>
                        { imagesSelected.map((element, key) => {
                            if(element.parrillaNumber === 4){
                                const fullPath = `${element.path}${element.name}`;
                                return <img key={key} src={fullPath} alt={element.name} />
                            }
                        })}
                    </button>
                </div>
            </div>
            <div className="col-md-6">
                <div className="row desayuno-flex-direction">
                    <div className={`desayuno-slider ${classDisplayForm} ${classDisplaySliderMobileShow}`}>
                        <div className={`row ${classDisplay}`}>
                            <div className="col-md-12">
                                <div className="desayuno-slider-image">
                                    <img src={pathImage} alt={nameImage}/>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className='desayuno-slider-list'>
                                    <SliderList listPackages={desayunoRackPackages} parrillaNumber={parrillaNumber} />
                                </div>
                            </div>
                        </div>
                        <div className={`row ${classDisplay}`}>
                                <div className="col-md-12 desayuno-slider-container">
                                    <button type='button' className='desayuno-rack-score-btn-return' onClick={onHideParrilla} ></button>
                                    <button type='button' disabled={!isImageSelected} onClick={onSavePackage} className='desayuno-rack-score-btn-check' ></button>
                                </div>
                        </div>
                    </div>
                    <div className="desayuno-open-button">
                        <button disabled={classDisplay === "" || parrillaNumber === 0} className="desayuno-button start-txt-registrar" onClick={onShowParrilla}>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </UILayout>
    { showMessage && <Alert message={textAlert} ok={ok} trigger={trigger}/> }
    </>
  )
}
