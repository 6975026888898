import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../store/auth/authSlice';
import { setUserInfo } from '../store/ui/uiSlice';
import { startCheckActivities } from '../store/ui/thunks';

export const useCheckAuth = (path) => {
    const { status } = useSelector( state => state.auth );
    const dispatch = useDispatch();
    
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if(!!user){
            const { apellidoMaterno, apellidoPaterno,
                idPuesto,idUsuario, idZona, nombre , numeroEmpleado } = user;
    
            const json = {
                id: idUsuario,
                employeeNumber: numeroEmpleado,
                positionId: idPuesto,
                name: nombre,
                middleName: apellidoMaterno,
                lastName: apellidoPaterno,
                zoneId: idZona,
                lastPath: path
           }
           dispatch(startCheckActivities(json.id))
           dispatch(setUserInfo(json));
           dispatch(login(json));
           
        }
    }, [])
    
    
    return {status};
}
