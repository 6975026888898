export const SelectComponent = ({ label, value, options, onChange, name }) => {
  return (
    <>
        <label htmlFor={name} className="form-label">{label}</label>
        <select id={name} name={name} value={value} className='form-control' onChange={onChange}>
            <option value=''>Seleccionar</option>
            {options.map((option) => (
            <option key={option.value} value={option.value}>{option.label}</option>
            ))}
        </select>
    </>
  )
}
