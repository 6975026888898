import React, { useState } from 'react';
import './HidratacionInicio.css';
import {AudioButton} from './AudioButton'
import { useNavigate } from 'react-router-dom';

const HidratacionPInicio = () => {
    const pathAudio = '/assets/Audios/hidratacion/hidratacion_presentacion.mp3';
    const navigate = useNavigate();
    const [shouldStop, setShouldStop] = useState(false);

    const onClick = () => {
        setShouldStop(true);

        setTimeout(() => {
            navigate('/hidratacionCooler')
          }, 100); // Puedes ajustar este tiempo si es necesario
    }
    return (
        <div className='container'>
            <div className="row mt-3">
                <div className='btn-audio-hidratacion'>
                <AudioButton pathAudio={pathAudio} shouldStop={shouldStop}/>
                </div>
            </div>
            <div className="row">
                <div className='generalContainderHidratacion'>
                    <div className="containerInicioHidratacion">
                        <div className="image-containerHidratacion">
                            
                        </div>
                        <div className="buttons-containerHidratacion">
                            <div className="top-buttonHidratacion"></div>
                            <button className="bottom-buttonHidratacion" onClick={onClick}></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HidratacionPInicio;
