import { useNavigate } from 'react-router-dom';
import './StartPage.css'
import { ButtonComponent } from '../components/ButtonComponent';
import { AuthLayout } from '../layout/AuthLayout';

export const StartPage = () => {
  const navigate = useNavigate();

  const onEnter = () => {
    navigate('/auth/login');
  }

  return (
    <AuthLayout>
      <div className='startPage-GeneralContainer'>
        <div className='startPage-Container'>
          <div className='startPage-VideoContainer'>
            <video className='startPage-video' src='/assets/videos/videoPresentacion.mp4'
            controls>
            </video>
          </div>
          <div className='startPage-BtnContainer'>
            <ButtonComponent onEvent={ onEnter } text="ENTRAR" classtext='start-btn-entrar' />
          </div>
        </div>
      </div>
    </AuthLayout>
  )
}
