import React from 'react';
import './MisMomentosInicio.css';
import { useNavigate } from 'react-router-dom';
import {AudioButton} from '../components/AudioButton'

export const MisMomentosPInicio = () => {
    const pathAudio = '/assets/Audios/mismomentos/mismomentos_presentacion.mp3';
    const navigate = useNavigate();
    const onClick = () => {
        navigate('/misMomentosCooler')
    }
    return (
        <div className='container'>
            <div className="row mt-3">
                <div className='btn-audio-mis-momentos'>
                    <AudioButton pathAudio={pathAudio} />
                </div>
            </div>
            <div className="row">
                <div className='generalContainderMisMomentos'>
                    <div className="containerInicioMisMomentos">
                        <div className="image-containerMisMomentos">
                            
                        </div>
                        <div className="buttons-containerMisMomentos">
                            <div className="top-buttonMisMomentos"></div>
                            <button className="bottom-buttonMisMomentos" onClick={onClick}></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
