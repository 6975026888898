import { Navigate, Route, Routes } from "react-router-dom"
import { LoginPage, RegisterPage, StartPage} from "../pages"

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route path="register" element={<RegisterPage />} />
      <Route path="start" element={<StartPage />} /> 

      <Route path="/*" element={<Navigate to="/auth/start" />} /> 
    </Routes>
  )
}
