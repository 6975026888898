import { useEffect, useMemo, useState } from 'react';

export const useForm = ( initialForm = {}, formValidations = {}) => {
    const regex = /^[0-9\b]+$/;

    const [ formState, setFormState ] = useState( initialForm );
    const [formValidation, setFormValidation] = useState({})

    useEffect(() => {
        createValidators();
      }, [formState]);

    useEffect(() => {
        setFormState( initialForm );
    }, [initialForm])
    
    const isFormValid = useMemo(() => {
        for (const formValue of Object.keys( formValidation )) {
            if( formValidation[formValue] !== null) return false;
        }
        return true;
    }, [ formValidation ]);

    const onInputChange = ({ target }) => {
        const { name, value } = target;
        if(name === 'employeeNumber'){
            if(value === "" || regex.test(value)){
                setFormState({
                    ...formState,
                    [ name ]: value
                });
            }
        } else if(name === 'name' || name === 'middleName' || name === 'lastName' ){
            const regex = /^[a-zA-Z\s]+$/;
            if(value === "" || regex.test(value)){
                setFormState({
                    ...formState,
                    [ name ]: value
                });
            }
        }else{
            setFormState({
                ...formState,
                [ name ]: value
            });
        }

        
    }

    const onResetForm = () => {
        setFormState( initialForm );
    }

    const createValidators = () => {
        const formCheckedValues = {};

        for (const formField of Object.keys( formValidations )) {
                const [ fn, errorMessage = 'This camp is required.' ] = formValidations[formField];

                formCheckedValues[`${ formField }Valid`] = fn( formState[formField] ) ? null : errorMessage;
        }

        setFormValidation(formCheckedValues);
    }

    return {
        ...formState,
        formState,
        onInputChange,
        onResetForm,
        ...formValidation,
        isFormValid
    }
}