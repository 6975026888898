
import { useEffect, useMemo, useState } from 'react'
import { useForm } from '../../hooks'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ButtonComponent } from '../components/ButtonComponent'
import { startLoginWithEmployeeNumberPassword, startchangeRegisterStatus } from '../../store/auth/thunks'
import { AuthLayout } from '../layout/AuthLayout'
import { Alert } from '../../globalcomponents/Alert'


import './LoginPage.css'


const formData = {
  employeeNumber: '',
  password: ''
}

const formValidations = {
  employeeNumber: [(value) => value.length === 8  , 'ESCRIBE UN NÚMERO DE EMPLEADO VÁLIDO'],
  password: [(value) => value.length >= 5, 'ESCRIBE LA CONTRASEÑA'],
}

export const LoginPage = () => {

  const navigate = useNavigate();

  const {isRegister, errorMessage, status } = useSelector( state => state.auth);

  const dispatch = useDispatch();
  const [formSubmitted, setformSubmitted] = useState(false)
  const [trigger, setTrigger] = useState(0);

  const { formState ,employeeNumber, password, onInputChange,
    isFormValid, employeeNumberValid, passwordValid  }= useForm( formData, formValidations );

  const isCheckingAuthentication = useMemo( () => status === 'checking', [status] );

  const onSubmit = ( event ) => {
    event.preventDefault();
    setformSubmitted(true);

    if( !isFormValid ) return;

    dispatch(startLoginWithEmployeeNumberPassword( formState ));
  }

  const onShowRegisterPage = () => {
      setTrigger(prev => prev + 1);
      dispatch(startchangeRegisterStatus())
      navigate('/auth/register');
  }

  useEffect(() => {
   
    if(isRegister){
      dispatch(startchangeRegisterStatus());
    }
  }, [isRegister, dispatch])


  return (
    <> 
    <AuthLayout>
      <div className='loginDad'>
        <div className='loginSon'>
          <form onSubmit={ onSubmit }>
            <div className="row">
              <div className='login-form-text-container'>
                <div className={`login-form ${(!isFormValid && formSubmitted) ? 'login-form-expand-errors' : ''}`}>
                  <div className='todoContainer'>
                    <label htmlFor="employeeNumber" className="login-form-label">NÚMERO DE EMPLEADO</label>
                    <div className="input-group flex-nowrap">
                              <span className="input-group-text" >MX0</span>
                                <input 
                                maxLength={8}
                                autoComplete='off'
                                type='text'
                                className='form-control' 
                                id='employeeNumber' 
                                placeholder='Número de empleado'
                                name='employeeNumber'
                                value={ employeeNumber }
                                onChange={ onInputChange }/>
                    </div>
                    <div className={`input-group-text bg-transparent ${(!!employeeNumberValid && formSubmitted) ? 'no-diplay-none' : 'd-none'}`}>
                        <div className={`invalid-feedback ${(!!employeeNumberValid && formSubmitted) && 'no-diplay-none'}` }>
                          {employeeNumberValid}
                        </div>
                    </div>
                  <div className={`${(!!passwordValid && formSubmitted) ? 'mb-0' : 'mb-3'}`}>
                    <label htmlFor="password" className="login-form-label">CONTRASEÑA</label>
                    <input 
                      maxLength={8}
                      autoComplete='off'
                      type="password" 
                      className="form-control" 
                      id="password" 
                      placeholder='Contraseña'
                      name='password'
                      value={ password }
                      onChange={ onInputChange }/>
                      <div className={`input-group-text bg-transparent ${(!!passwordValid && formSubmitted) ? 'no-diplay-none' : 'd-none'}`}>
                        <div className={`invalid-feedback ${(!!passwordValid && formSubmitted) && 'no-diplay-none'}` }>
                          {passwordValid}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className='login-btns-container'>
                <div className='login-btns-subcontainer'>
                  <button type='button' onClick={ onShowRegisterPage } className='login-start-btn-registrar'></button>
                  <ButtonComponent disabled={isCheckingAuthentication} type='submit' text='' classtext='login-btn-entrar' onEvent={ onsubmit }/>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </AuthLayout>
    { errorMessage != null && <Alert  message= 'VERIFICA LOS DATOS' trigger={trigger}/> }
    </>
  )
}
