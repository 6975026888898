import { InitialTest } from "./InitialTest"
import {useDispatch, useSelector } from "react-redux";
import { MainMenu } from "./MainMenu";
import { useEffect, useState } from "react";
import { startCheckActivities, startCheckTestDone } from "../../store/ui/thunks";
import { Score } from "./Score";

export const UIPage = () => {
  const dispatch = useDispatch()
  const { doTest, id, score, doAllActivities } = useSelector( state => state.ui);

  
  const [state, setEstado] = useState(false);

  useEffect(() => {
    dispatch(startCheckTestDone(id))
    dispatch(startCheckActivities(id));
  }, [dispatch, id])

  setTimeout(() => {
     setEstado(true)
  }, 300);

  return (
    <>
      {   
        (state && !!doTest) && ((score === null && !doTest.testBegin) ? <InitialTest typeTest={1} /> :
        (score === null && !doTest.testEnd && doAllActivities) ? <InitialTest typeTest={2} /> : 
        (doTest.testBegin && score === null) ? <MainMenu /> :  <Score score={score} id={id}/>)
      }
    </>
  )
}
