import React, { useEffect } from 'react';
import './DesayunoAnaquelResult.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { startInitalState } from '../../../store/ui/thunks';
// import {AudioButton} from './AudioButton'

export const DesayunoAnaquelResult = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {firstActivityScore, secondActivityScore} = useSelector( state => state.ui);

    const finalScore = firstActivityScore + secondActivityScore;

    const onClick = () => {
        dispatch(startInitalState());
        navigate('/'); 
    }

    useEffect(() => {
        window.location.hash="No-back-button";
       window.location.hash="Again-No-back-button" //chrome
       window.onhashchange=function(){window.location.hash="No-back-button";}
    }, [])
    return (
        <>
        <div className='generalContainderDesayunoAnaquelesResult'>
            {/* <div>
                <AudioButton />
            </div> */}
            <div className="containerInicioDesayunoAnaquelesResult">
                <div className="imagecontainerDesayunoAnaquelesResult">
                    <button className="buttonDesayunoAnaquelesResult" onClick={onClick}></button>
                    <p className='scoreAnaquelesDesayuno'>{finalScore}</p>
                </div>
            </div>
        </div>
        </>
    );
}