import { createSlice } from "@reduxjs/toolkit";

export const uiSlice = createSlice({
    name: 'ui',
    initialState:{
        id: null,
        doTest: null,
        errorMessage: null,
        testCorrect: false,
        testAnswers: [],
        score: null,
        activeImage: {
            id: null,
            name: null,
            path: null,
            parrillaNumber: null
        },
        imagesSelected: [],
        imagesComunicationSelected: [],
        firstActivityScore: null,
        secondActivityScore: null,
        doAllActivities: false,
        activitiesInformation: null
    },
    reducers:{
        setUserInfo: (state, { payload }) => {
            state.id = payload.id
        },
        setDoTest: (state, { payload }) => {
            state.doTest = payload;
        },
        setError: (state, { payload } ) => {
            state.errorMessage = payload
        },
        setTestCorrect: (state, { payload }) => {
            state.testCorrect = payload.correct;
            state.testAnswers = {
                ...state.testAnswers,
                ...payload.answersSelected
            }
        },
        setTestScore: (state, { payload }) => {
            state.score = payload
        },
        setActiveImage: (state, {payload}) => {
            state.activeImage = {
                ...payload
            }
        }
        ,
        setStoreImageComunicationSelected: (state, { payload }) => {
            state.imagesComunicationSelected =  state.imagesComunicationSelected.filter((element) => {
                return element.parrillaNumber !== payload.parrillaNumber
            });
            state.imagesComunicationSelected = [
                ...state.imagesComunicationSelected,  {
                    ...payload
                }
            ]
            
        },
        setStoreImageSelected: (state, { payload }) => {
            state.imagesSelected =  state.imagesSelected.filter((element) => {
                return element.parrillaNumber !== payload.parrillaNumber
            });
            state.imagesSelected = [
                ...state.imagesSelected,  {
                    ...payload
                }
            ]
            
        },
        setScoreFirstActivity: (state, { payload }) => {
            state.firstActivityScore = payload
        },
        setScoreSecondActivity: (state, { payload }) => {
            state.secondActivityScore = payload
        },
        setInitalState: (state, { payload }) => {
            state.score= null;
            state.activeImage.id = null;
            state.activeImage.name = null;
            state.activeImage.path = null;
            state.activeImage.parrillaNumber = null;
            state.imagesSelected= [];
            state.imagesComunicationSelected= [];
            state.firstActivityScore= null;
            state.secondActivityScore= null;
        },
        setDoAllActivities: (state, {payload}) => {
            state.doAllActivities = payload
        },
        setActivitiesInformation : (state, {payload}) => {
            state.activitiesInformation = payload;
        },
        setTestReset: (state) => {
            state.testAnswers = [];
            state.testCorrect = false;
        }
    }
})

export const { setUserInfo, setDoTest, setError, setTestCorrect,
    setTestScore, setActiveImage, setStoreImageSelected,
    setScoreFirstActivity, setScoreSecondActivity,
    setStoreImageComunicationSelected, setInitalState, setDoAllActivities,
    setActivitiesInformation, setTestReset} = uiSlice.actions;
