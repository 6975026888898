import Glider from "react-glider";
import "glider-js/glider.min.css";
import "./SliderList.css";
import { SliderItemList } from "./SliderItemList";

export const SliderList= ({listPackages=[], parrillaNumber = 0}) =>  {

  return (
    <div className="SliderListMyMoments">
        <Glider
          slidesToShow={3}
          slidesToScroll={3}
          draggable
        >
          {
            listPackages.map((element, key) => {
              return  <SliderItemList key={key} {...element} parrillaNumber={parrillaNumber} />
            })
          }
        </Glider>
    </div>
  );
}
