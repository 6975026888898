import { Navigate, Route, Routes } from "react-router-dom"
import { UIPage } from "../pages/UIPage"
import { MisMomentosCooler, MisMomentos, MisMomentosCoolerComunicacion,
  Hidratacion, HidratacionCooler, HidratacionCoolerComunicacion,
  HidratacionRack, HidratacionRackComunicacion,
  Desayuno, DesayunoCooler, DesayunoCoolerComunicacion,
  DesayunoRack, DesayunoRackComunicacion,
  Comidas, ComidasCooler, ComidasCoolerComunicacion, ComidasRack,
  ComidasRackComunicacion, ComidasTortillero, 
  ComidasTortilleroComunicacion, Sabores } from "../pages"
import { 
  ComidasCoolerResult, ComidasRackResult, ComidasTortilleroResult, 
  DesayunoCoolerResult, DesayunoRackResult, 
  HidratacionCoolerResult,HidratacionRackResult,
  MisMomentosCoolerResult} from "../components";
import { EleccionBotonesInicio } from "../components/Sabores/EleccionBotonesInicio";
import { SaboresCooler } from "../pages/Sabores/SaboresCooler";
import { SaboresCoolerFresca } from "../pages/Sabores/SaboresCoolerFresca";
import { SaboresCoolerComunicacion } from "../pages/Sabores/SaboresCoolerComunicacion";
import { SaboresCoolerSidralResult } from "../components/Sabores/SaboresCoolerSidralResult";
import { SaboresCoolerComunicacionFresca } from "../pages/Sabores/SaboresCoolerComunicacionFresca";
import { SaboresCoolerFrescaResult } from "../components/Sabores/SaboresCoolerFrescaResult";
import { ComidasAnaqueles } from "../pages/Comidas/ComidasAnaqueles";
import { ComidasAnaquelesComunicacion } from "../pages/Comidas/ComidasAnaquelesComunicacion";
import { ComidasAnaquelResult } from "../components/Comidas/ComidasAnaquelResult";
import { HidratacionAnaquelResult } from "../components/Hidratacion/HidratacionAnaquelResult";
import { DesayunoAnaquelResult } from "../components/Desayuno/DesayunoAnaquelResult";
import { HidratacionAnaqueles } from "../pages/Hidratacion/HidratacionAnaqueles";
import { HidratacionAnaquelesComunicacion } from "../pages/Hidratacion/HidratacionAnaquelesComunicacion";
import { DesayunoAnaquelesComunicacion } from "../pages/Desayuno/DesayunoAnaquelesComunicacion";
import { DesayunoAnaqueles } from "../pages/Desayuno/DesayunoAnaqueles";
import { MisMomentosEnfriador } from "../pages/MyMoments/MisMomentosEnfriador";
import { MisMomentosEnfriadorResult } from "../components/MyMoments/MisMomentosEnfriadorResult";

export const UIRoutes = () => {
  return (
    <Routes>
        <Route path="/" element={ <UIPage />} />
        <Route path="misMomentosCooler" element={ <MisMomentosCooler />} />
        <Route path="misMomentosCoolerPublicidad" element={ <MisMomentosCoolerComunicacion />} />
        <Route path="puntuacionFinal" element={ <MisMomentosCoolerResult />} />
        <Route path="misMomentosEnfriador" element={ <MisMomentosEnfriador />} />
        <Route path="misMomentosEnfriadorResult" element={ <MisMomentosEnfriadorResult />} />
        <Route path="comidas" element={ <Comidas />} />
        <Route path="comidasCooler" element={ <ComidasCooler />} />
        <Route path="comidasCoolerComunicacion" element={ <ComidasCoolerComunicacion />} />
        <Route path="comidasCoolerResultado" element={ <ComidasCoolerResult />} />
        <Route path="comidasRack" element={ <ComidasRack />} />
        <Route path="comidasRackComunicacion" element={ <ComidasRackComunicacion />} />
        <Route path="comidasRackResultado" element={ <ComidasRackResult />} />
        <Route path="comidasTortillero" element={ <ComidasTortillero />} />
        <Route path="comidasTortilleroComunicacion" element={ <ComidasTortilleroComunicacion />} />
        <Route path="comidasTortilleroResult" element={ <ComidasTortilleroResult />} />
        <Route path="comidasAnaqueles" element={ <ComidasAnaqueles />} />
        <Route path="comidasAnaquelesComunicacion" element={ <ComidasAnaquelesComunicacion />} />
        <Route path="comidasAnaquelesResult" element={ <ComidasAnaquelResult />} />
        <Route path="mismomentos" element={ <MisMomentos />} />
        <Route path="hidratacion" element={ <Hidratacion />} />
        <Route path="hidratacionCooler" element={ <HidratacionCooler />} />
        <Route path="hidratacionCoolerComunicacion" element={ <HidratacionCoolerComunicacion />} />
        <Route path="hidratacionCoolerResultado" element={ <HidratacionCoolerResult />} />
        <Route path="hidratacionRack" element={ <HidratacionRack />} />
        <Route path="hidratacionRackComunicacion" element={ <HidratacionRackComunicacion />} />
        <Route path="hidratacionRackResultado" element={ <HidratacionRackResult />} />
        <Route path="hidratacionAnaqueles" element={ <HidratacionAnaqueles />} />
        <Route path="hidratacionAnaquelesComunicacion" element={ <HidratacionAnaquelesComunicacion />} />
        <Route path="hidratacionAnaquelesResult" element={ <HidratacionAnaquelResult />} />
        <Route path="desayuno" element={ <Desayuno />} />
        <Route path="desayunoCooler" element={ <DesayunoCooler />} />
        <Route path="desayunoCoolerComunicacion" element={ <DesayunoCoolerComunicacion />} />
        <Route path="desayunoCoolerResultado" element={ <DesayunoCoolerResult />} />
        <Route path="desayunoRack" element={ <DesayunoRack />} />
        <Route path="desayunoRackComunicacion" element={ <DesayunoRackComunicacion />} />
        <Route path="desayunoRackResultado" element={ <DesayunoRackResult />} />
        <Route path="desayunoAnaqueles" element={ <DesayunoAnaqueles />} />
        <Route path="desayunoAnaquelesComunicacion" element={ <DesayunoAnaquelesComunicacion />} />
        <Route path="desayunoAnaquelesResult" element={ <DesayunoAnaquelResult />} />
        <Route path="HidratacionCoolerPuntuacionFinal" element={ <HidratacionCoolerResult />} />
        <Route path="sabores" element={ <Sabores />} />
        <Route path="saboresEleccion" element={ <EleccionBotonesInicio />} />
        <Route path="saboresCooler" element={ <SaboresCooler />} />
        <Route path="saboresCoolerComunicacion" element={ <SaboresCoolerComunicacion />} />
        <Route path="saboresSidralCoolerResultado" element={ <SaboresCoolerSidralResult />} />
        <Route path="saboresCoolerFresca" element={ <SaboresCoolerFresca />} />
        <Route path="saboresCoolerFrescaComunicacion" element={ <SaboresCoolerComunicacionFresca />} />
        <Route path="saboresFrescaCoolerResultado" element={ <SaboresCoolerFrescaResult />} />
        <Route path="/*" element={ <Navigate to="/" />} />
    </Routes>
  )
}
