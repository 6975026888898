import { useDispatch } from "react-redux";
import { startActiveImage } from "../../store/ui/thunks";

export const SliderItemList = ({id, name, path, parrillaNumber}) => {
    const dispatch = useDispatch();

    const fullPath = `${path}${name}`;

    const showImage = (img) => {
        dispatch(startActiveImage(id, name, path, parrillaNumber));
    }
  return (
    <div className="slide">
        <button onClick={() => showImage(fullPath)} className="btn-sliderlist-img"><img src={fullPath} alt={name}/></button>
    </div>
  )
}
