import { useDispatch, useSelector } from "react-redux";
import { ButtonComponent } from "../../auth/components/ButtonComponent";
import { QuestionComponent } from "../components/QuestionComponent"

import './InitialTest.css';
import { startCheckTestDone, startRegisterTest, startResetTestAnswers } from "../../store/ui/thunks";
import { Alert } from "../../globalcomponents/Alert";

const jsonList = 
[
  {
    "id": 1,
    "description": "1. ¿Cuáles son las plataformas por ocasión de consumo?",
    "answers":
    [
      {
          "id": "A",
          "description": "a) Comidas, Mis momentos, hidratación, desayuno, Antojo."
      },
      {
          "id": "B",
          "description": "b) Comidas, Mis momentos, hidratación, Nutrición, Retornabilidad y Antojo."
      },
      {
          "id": "C",
          "description": "c) Comidas, hidratación, desayuno, Retornabilidad y Socialización."
      }
    ]
  },
  {
    "id": 2,
    "description": "2. ¿Cuáles son los atributos que hacen válida una plataforma?",
    "answers":
    [
      {
          "id": "A",
          "description": "a) Portafolio y Comunicación."
      },
      {
          "id": "B",
          "description": "b) Portafolio, activación y comunicación."
      },
      {
          "id": "C",
          "description": "c) Comunicación y Respeto."
      }
    ]
  },
  {
    "id": 3,
    "description": "3. ¿Hasta cuántos materiales de la competencia se permiten en nuestros espacios?",
    "answers":
    [
      {
          "id": "A",
          "description": "a) 2"
      },
      {
          "id": "B",
          "description": "b) 1"
      },
      {
          "id": "C",
          "description": "c) 0"
      }
    ]
  },
  {
    "id": 4,
    "description": "4. ¿Qué roles se ejecutan en la plataforma de Comidas?",
    "answers":
    [
      {
          "id": "A",
          "description": "a) CC SS, NECTAR MS, BU y AGUA."
      },
      {
          "id": "B",
          "description": "b) CC 500 VR, Mundet Sabores, Ciel Mineral MS y Colas MS."
      },
      {
          "id": "C",
          "description": "c) CC MS RET Y NR, SABORES MS, CC SA MS Y VALLE FRUT MS."
      }
    ]
  },
  {
    "id": 5,
    "description": "5. ¿En qué parte del enfriador se ejecuta la comunicación de la ocasión de consumo?",
    "answers":
    [
      {
          "id": "A",
          "description": "a) Entre parrillas."
      },
      {
          "id": "B",
          "description": "b) Parte Superior."
      },
      {
          "id": "C",
          "description": "c) Parte Inferior."
      }
    ]
  }
]

export const InitialTest = ({ typeTest = 1}) => {
  const dispatch = useDispatch();

  const { testCorrect, testAnswers = [], id, errorMessage} = useSelector( state => state.ui);
  const onClick = () => {
    const json = getScore(testAnswers);
    dispatch(startRegisterTest(json, errorMessage))
    dispatch(startCheckTestDone(id))
    dispatch(startResetTestAnswers())
  }

  const getScore = (testAnswers) => {

    let maxScore = 10
    let score = 0;
    let correctAnswers = 0;

    const jsonArray = {
      "respuestas": []
    };

    for (var i in testAnswers) {
      let isCorrect = "N";
      switch (testAnswers[i].idSection) {
        case 1:
          if(testAnswers[i].id === "A"){
            isCorrect = "S";
            correctAnswers++;
          }
          break;
        case 2:
          if(testAnswers[i].id === "B"){
            isCorrect = "S"
            correctAnswers++;
          }
          break;
        case 3:
          if(testAnswers[i].id === "C"){
            isCorrect = "S"
            correctAnswers++;
          }
          break;
        case 4:
          if(testAnswers[i].id === "C"){
            isCorrect = "S"
            correctAnswers++;
          }
          break;
      
        default:
          if(testAnswers[i].id === "B"){
            isCorrect = "S"
            correctAnswers++;
          }
          break;
      }

      jsonArray.respuestas.push({
        "idPregunta":testAnswers[i].idSection,
        "respuesta": testAnswers[i].id,
        "correcta": isCorrect,
        "puntos" : (isCorrect === "S") ? 2.0  : 0.0
      });
    }

    score = correctAnswers*maxScore/Object.keys(testAnswers).length

    jsonArray.idUsuario = id;
    jsonArray.tipo = typeTest;
    jsonArray.puntos = score;

    return jsonArray;
  }

  

  return (
    <div className="container testContainer">
      <div className="row mt-5 center-parent">
        <div className="col-md-12 with-container center-child">
          <form >
            { jsonList.map((element, i) => {
                return <QuestionComponent key={i} { ...element } />
            })}
              <ButtonComponent disabled={ !testCorrect }  onEvent={onClick} classtext="btn-test" text="Listo" />
          </form>
        </div>
        { errorMessage != null && <Alert message= 'OCURRIO UN ERROR AL REALIZAR EL REGISTRO' /> }
      </div>
    </div>
  )
}
