import React, { useState, useEffect, useRef } from 'react';
import './Alert.css';


export const Alert = ({ message, duration = 4000, ok=false , trigger}) => {
    const [state, setEstado] = useState('show');
    const imgShow = (ok) ? "/assets/img/UI/Img_bg_reel_Correct.png" : "/assets/img/UI/Img_bg_reel_Warn.png";

    // Al cambiar el mensaje, reiniciar el estado a 'show'
    useEffect(() => {
        setEstado('show'); // Resetea el estado cada vez que cambie el mensaje

        const timer = setTimeout(() => {
            setEstado('hide');
        }, duration);

        return () => clearTimeout(timer);
    }, [trigger, duration]); // Dependencias en message y duration

    return (
        <div className={`alert-container ${state}`}>
            <div className='aler-card'>
                <img src={imgShow} alt="Fondo de alerta" className="alert-img" />
                <div className="alert-text">{message}</div>
            </div>
        </div>
    );
};