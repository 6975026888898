import { AnswerComponent } from "./AnswerComponent"
import { startCheckTestCorrect } from "../../store/ui/thunks";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
let answersSelected = [];

export const QuestionComponent = ( { description, id, answers = [] }) => {

  const dispatch = useDispatch();
  const { testAnswers = [] } = useSelector( state => state.ui);

  const onClickAnswer = (e, id, idSection) => {
    e.preventDefault();

    const element = answersSelected.filter((element) => {
      return element.idSection === idSection
    });
    if(element.length > 0){
      answersSelected = answersSelected.filter((element) => {
        return element.idSection !== idSection
      });
    }

    answersSelected.push({ id,idSection});
  
    if(answersSelected.length < 5){
      dispatch(startCheckTestCorrect(false, answersSelected));
      return;
    } 

    dispatch(startCheckTestCorrect(true, answersSelected));
  }

  useEffect(() => {
    if(Object.keys(testAnswers).length === 0){
      answersSelected =[];
    }
  }, [])

  return (
    <div className="panel panel-primary" id="result_panel">
        <div className="panel-heading"><h3 className="panel-title">{description}</h3>    </div>
        <div className="panel-body">
            <ul className="list-group">
            { answers.map((element, i) => {
                return <AnswerComponent key={i} {...element}  idSection={id} event={onClickAnswer} />
            })}
            </ul>
        </div>
    </div>
    
  )
}
