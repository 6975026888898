import {useEffect, useMemo, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../hooks';
import { startRegister, startchangeRegisterStatus } from '../../store/auth/thunks';
import { ButtonComponent } from '../components/ButtonComponent'
import { SelectComponent } from '../components/SelectComponent';
import { AuthLayout } from '../layout/AuthLayout'
import { Alert } from '../../globalcomponents/Alert'

import './RegisterPage.css'


const formData = {
  employeeNumber: '',
  position: '',
  name: '',
  middleName: '',
  lastName: '',
  zone: '',
  password: ''
}

const formValidations = {
  employeeNumber: [(value) => value.length === 8  , 'EL NÚMERO DE EMPLEADO DEBE SER DE 8 NUMEROS'],
  name: [(value) => value.length >= 1, 'ESCRIBE UN NOMBRE'],
  position: [(value) => value.length >= 1, 'SELECCIONA EL PUESTO'],
  middleName: [(value) => value.length >= 1, 'ESCRIBE TU APELLIDO PATERNO'],
  lastName: [(value) => value.length >= 1, 'ESCRIBE TU APELLIDO MATERNO'],
  zone: [(value) => value.length >= 1, 'SELECCIONA LA ZONA'],
  password: [(value) => value.length >= 5, 'LA CONTRASEÑA DEBE TENER AL MENOS 5 CARACTERES'],
}

const optionsPosition = [
  { label: 'Líder ventas', value: '1' },
  { label: 'Jefe ventas', value: '2' },
  { label: 'Coordinador ventas', value: '3' },
  { label: 'Supervisor ventas', value: '4' },
  { label: 'Preventa', value: '5' },
  { label: 'Desarrollador comercial', value: '6' },
];

const optionsZone = [
  { label: 'Oficina de servicios', value: '1' },
  { label: 'Valle de México', value: '2' },
  { label: 'Monarca', value: '3' },
  { label: 'Centro Pacífico', value: '4' },
  { label: 'Golfo', value: '5' },
  { label: 'Sureste', value: '6' },
  { label: 'Bajio', value: '7' },
];


export const RegisterPage = () => {

  const navigate = useNavigate();
  const {isRegister, errorMessage, status } = useSelector( state => state.auth);

  const isCheckingAuthentication = useMemo( () => status === 'checking', [status] );

  const dispatch = useDispatch();
  const [formSubmitted, setformSubmitted] = useState(false)
  const [trigger, setTrigger] = useState(0);

  const { formState ,employeeNumber, position, name,
    middleName,lastName,zone,password,
     onInputChange,
     isFormValid, employeeNumberValid, nameValid, positionValid,
     middleNameValid, lastNameValid, zoneValid, passwordValid }= useForm( formData, formValidations );

  const onSubmit = ( event ) => {
    event.preventDefault();
    setformSubmitted(true);
    setTrigger(prev => prev + 1);

    if( !isFormValid ) return;

    dispatch(startRegister( formState, errorMessage ));
  }

  const onReturnLogin = () => {
    dispatch(startchangeRegisterStatus())
    navigate('/auth/login');
  }  

  useEffect(() => {
    if(isRegister){
      setTimeout(() => {
        navigate('/auth/login');
      }, 4000);
    }
  }, [isRegister, navigate])
  

  
  return (
    <>
    <AuthLayout>
      <div className='generalContainerRegister'>
        <div className='register-subgeneral-container'>
          <div className='register-btn-return-container mt-3'>
            <button type='button' className='start-btn-return' onClick={ onReturnLogin }></button>
          </div>
          <div className='register-form-container'>
            <form onSubmit={ onSubmit } className='register-form-form'>
              <div className='register-img-form-container'>
                  <div className={`register-form ${(!isFormValid && formSubmitted) ? 'register-form-expand-errors' : ''}`}>
                    <div className='row'>
                      <div className='col-md-12 mt-4'>
                          <div className={`${(!!employeeNumberValid && formSubmitted) ? 'mb-0' : 'mb-3 mb-3-mobile'}`}>
                            <label htmlFor="employeeNumber" className="form-label">NÚMERO DE EMPLEADO</label>
                              <div className="input-group flex-nowrap">
                                <span className="input-group-text" >MX0</span>
                                <input 
                                maxLength={8}
                                autoComplete='off'
                                type='text'
                                className='form-control' 
                                id='employeeNumber' 
                                placeholder='Número de empleado'
                                name='employeeNumber'
                                value={ employeeNumber }
                                onChange={ onInputChange }
                                />
                            </div>
                            <div className={`input-group-text bg-transparent ${(!!employeeNumberValid && formSubmitted) ? 'no-diplay-none' : 'd-none'}`}>
                                  <div className={`invalid-feedback ${(!!employeeNumberValid && formSubmitted) && 'no-diplay-none'}` }>
                                    {employeeNumberValid}
                                  </div>
                              </div>
                          </div>
                          <div className={`${(!!positionValid && formSubmitted) ? 'mb-0' : 'mb-3 mb-3-mobile'}`}>
                            <SelectComponent 
                              label="PUESTO"
                              options={ optionsPosition }
                              value={ position }
                              name='position' 
                              onChange={ onInputChange } />
                              <div className={`input-group-text bg-transparent ${(!!positionValid && formSubmitted) ? 'no-diplay-none' : 'd-none'}`}>
                                  <div className={`invalid-feedback ${(!!positionValid && formSubmitted) && 'no-diplay-none'}` }>
                                    {positionValid}
                                  </div>
                              </div>
                          </div>
                          <div className={`${(!!nameValid && formSubmitted) ? 'mb-0' : 'mb-3 mb-3-mobile'}`}>
                            <label htmlFor="name" className="form-label">NOMBRE</label>
                            <input 
                              maxLength={20}
                              autoComplete='off'
                              type="text" 
                              className="form-control" 
                              id="name" 
                              placeholder='Nombre'
                              name='name'
                              value={ name }
                              onChange={ onInputChange }/>
                              <div className={`input-group-text bg-transparent ${(!!nameValid && formSubmitted) ? 'no-diplay-none' : 'd-none'}`}>
                                  <div className={`invalid-feedback ${(!!nameValid && formSubmitted) && 'no-diplay-none'}` }>
                                    {nameValid}
                                  </div>
                              </div>
                          </div>
                          <div className={`${(!!middleNameValid && formSubmitted) ? 'mb-0' : 'mb-3 mb-3-mobile'}`}>
                            <label htmlFor="middleName" className="form-label">APELLIDO PATERNO</label>
                            <input 
                              maxLength={15}
                              autoComplete='off'
                              type="text" 
                              className="form-control" 
                              id="middleName" 
                              placeholder='Apellido Paterno'
                              name='middleName'
                              value={ middleName }
                              onChange={ onInputChange }/>
                              <div className={`input-group-text bg-transparent ${(!!middleNameValid && formSubmitted) ? 'no-diplay-none' : 'd-none'}`}>
                                  <div className={`invalid-feedback ${(!!middleNameValid && formSubmitted) && 'no-diplay-none'}` }>
                                    {middleNameValid}
                                  </div>
                              </div>
                          </div>
                          <div className={`${(!!lastNameValid && formSubmitted) ? 'mb-0' : 'mb-3 mb-3-mobile'}`}>
                            <label htmlFor="lastName" className="form-label">APELLIDO MATERNO</label>
                            <input 
                              maxLength={15}
                              autoComplete='off'
                              type="text" 
                              className="form-control" 
                              id="lastName" 
                              placeholder='Apellido Materno'
                              name='lastName'
                              value={ lastName }
                              onChange={ onInputChange }/>
                              <div className={`input-group-text bg-transparent ${(!!lastNameValid && formSubmitted) ? 'no-diplay-none' : 'd-none'}`}>
                                  <div className={`invalid-feedback ${(!!lastNameValid && formSubmitted) && 'no-diplay-none'}` }>
                                    {lastNameValid}
                                  </div>
                              </div>
                          </div>
                          <div className={`${(!!zoneValid && formSubmitted) ? 'mb-0' : 'mb-3 mb-3-mobile'}`}>
                            <SelectComponent 
                              label="ZONA"
                              options={ optionsZone }
                              value={ zone }
                              name='zone' 
                              onChange={ onInputChange } />
                              <div className={`input-group-text bg-transparent ${(!!zoneValid && formSubmitted) ? 'no-diplay-none' : 'd-none'}`}>
                                  <div className={`invalid-feedback ${(!!zoneValid && formSubmitted) && 'no-diplay-none'}` }>
                                    {zoneValid}
                                  </div>
                              </div>
                          </div>
                          <div className={`${(!!passwordValid && formSubmitted) ? 'mb-0' : 'mb-3 mb-3-mobile'}`}>
                            <label htmlFor="password" className="form-label">CONTRASEÑA</label>
                            <input 
                              maxLength={8}
                              autoComplete='off'
                              type="password" 
                              className="form-control" 
                              id="password" 
                              placeholder='Contraseña'
                              name='password'
                              value={ password }
                              onChange={ onInputChange }/>
                              <div className={`input-group-text bg-transparent ${(!!passwordValid && formSubmitted) ? 'no-diplay-none' : 'd-none'}`}>
                                  <div className={`invalid-feedback ${(!!passwordValid && formSubmitted) && 'no-diplay-none'}` }>
                                    {passwordValid}
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
            </div>
            <div className='register-btn-registrar-container'>
                <ButtonComponent disabled={isCheckingAuthentication} type='submit' text='REGISTRAR' onEvent={ onsubmit} classtext='register-btn-registrar'/>
            </div>
            </form>
          </div>
        </div>
      </div>
    </AuthLayout>
    { isRegister && <Alert message= 'REGISTRADO' ok={true} trigger={trigger} />}
    { errorMessage != null && <Alert message={errorMessage} trigger={trigger}/> }
    </>
  )
}
