
/**BEGIN COMIDAS */
export const comidasCoolerPackages = [{
    "id": 1,
    "name": "Img_Comidas_Cooler_Paquete_05.png",
    "path": "/assets/img/ImgComidas/Cooler/Incorrecto_Cooler/"
  },
  {
    "id": 2,
    "name": "Img_Comidas_Cooler_Paquete_01.png",
    "path": "/assets/img/ImgComidas/Cooler/"
  },
  {
    "id": 3,
    "name": "Img_Comidas_Cooler_Paquete_06.png",
    "path": "/assets/img/ImgComidas/Cooler/Incorrecto_Cooler/"
  },
  {
    "id": 4,
    "name": "Img_Comidas_Cooler_Paquete_07.png",
    "path": "/assets/img/ImgComidas/Cooler/Incorrecto_Cooler/"
  },
  {
    "id": 5,
    "name": "Img_Comidas_Cooler_Paquete_02.png",
    "path": "/assets/img/ImgComidas/Cooler/"
  },
  {
    "id": 6,
    "name": "Img_Comidas_Cooler_Paquete_08.png",
    "path": "/assets/img/ImgComidas/Cooler/Incorrecto_Cooler/"
  },
  {
    "id": 7,
    "name": "Img_Comidas_Cooler_Paquete_09.png",
    "path": "/assets/img/ImgComidas/Cooler/Incorrecto_Cooler/"
  },
  {
    "id": 8,
    "name": "Img_Comidas_Cooler_Paquete_03.png",
    "path": "/assets/img/ImgComidas/Cooler/"
  },
  {
    "id": 9,
    "name": "Img_Comidas_Cooler_Paquete_10.png",
    "path": "/assets/img/ImgComidas/Cooler/Incorrecto_Cooler/"
  },
  {
    "id": 10,
    "name": "Img_Comidas_Cooler_Paquete_11.png",
    "path": "/assets/img/ImgComidas/Cooler/Incorrecto_Cooler/"
  },
  {
    "id": 11,
    "name": "Img_Comidas_Cooler_Paquete_12.png",
    "path": "/assets/img/ImgComidas/Cooler/Incorrecto_Cooler/"
  },
  {
    "id": 12,
    "name": "Img_Comidas_Cooler_Paquete_13.png",
    "path": "/assets/img/ImgComidas/Cooler/Incorrecto_Cooler/"
  },
  {
    "id": 13,
    "name": "Img_Comidas_Cooler_Paquete_04.png",
    "path": "/assets/img/ImgComidas/Cooler/"
  },
  {
    "id": 14,
    "name": "Img_Comidas_Cooler_Paquete_14.png",
    "path": "/assets/img/ImgComidas/Cooler/Incorrecto_Cooler/"
  },
  {
    "id": 15,
    "name": "Img_Comidas_Cooler_Paquete_15.png",
    "path": "/assets/img/ImgComidas/Cooler/Incorrecto_Cooler/"
  }];

  export const comidasCoolerComunicacionPackages = [ 
    {
        "id": 1,
        "name": "Img_Comunicacion_Comidas_Cooler_01.png",
        "path": "/assets/img/ImgComidas/Cooler/Comunicacion_Comidas_Cooler/"
      },
      {
        "id": 2,
        "name": "Img_Comunicacion_Comidas_Cooler_04.png",
        "path": "/assets/img/ImgComidas/Cooler/Comunicacion_Comidas_Cooler/Incorrecto/"
      },
      {
        "id": 3,
        "name": "Img_Comunicacion_Comidas_Cooler_06.png",
        "path": "/assets/img/ImgComidas/Cooler/Comunicacion_Comidas_Cooler/Incorrecto/"
      },{
    "id": 4,
    "name": "Img_Comunicacion_Comidas_Cooler_02.png",
    "path": "/assets/img/ImgComidas/Cooler/Comunicacion_Comidas_Cooler/Incorrecto/"
  },
  {
    "id": 5,
    "name": "Img_Comunicacion_Comidas_Cooler_03.png",
    "path": "/assets/img/ImgComidas/Cooler/Comunicacion_Comidas_Cooler/"
  },
  {
    "id": 6,
    "name": "Img_Comunicacion_Comidas_Cooler_07.png",
    "path": "/assets/img/ImgComidas/Cooler/Comunicacion_Comidas_Cooler/Incorrecto/"
  },
  {
    "id": 7,
    "name": "Img_Comunicacion_Comidas_Cooler_08.png",
    "path": "/assets/img/ImgComidas/Cooler/Comunicacion_Comidas_Cooler/Incorrecto/"
  },
  {
    "id": 8,
    "name": "Img_Comunicacion_Comidas_Cooler_05.png",
    "path": "/assets/img/ImgComidas/Cooler/Comunicacion_Comidas_Cooler/"
  }];
  
  export const comidasRackPackages = [{
    "id": 1,
    "name": "Img_Comidas_Rack_Paquete_05.png",
    "path": "/assets/img/ImgComidas/Rack/Incorrectos_Rack/"
  },
  {
    "id": 2,
    "name": "Img_Comidas_Rack_Paquete_06.png",
    "path": "/assets/img/ImgComidas/Rack/Incorrectos_Rack/"
  },
  {
    "id": 3,
    "name": "Img_Comidas_Rack_Paquete_01.png",
    "path": "/assets/img/ImgComidas/Rack/"
  },
  {
    "id": 4,
    "name": "Img_Comidas_Rack_Paquete_07.png",
    "path": "/assets/img/ImgComidas/Rack/Incorrectos_Rack/"
  },
  {
    "id": 5,
    "name": "Img_Comidas_Rack_Paquete_08.png",
    "path": "/assets/img/ImgComidas/Rack/Incorrectos_Rack/"
  },
  {
    "id": 6,
    "name": "Img_Comidas_Rack_Paquete_09.png",
    "path": "/assets/img/ImgComidas/Rack/Incorrectos_Rack/"
  },
  {
    "id": 7,
    "name": "Img_Comidas_Rack_Paquete_02.png",
    "path": "/assets/img/ImgComidas/Rack/"
  },
  {
    "id": 8,
    "name": "Img_Comidas_Rack_Paquete_10.png",
    "path": "/assets/img/ImgComidas/Rack/Incorrectos_Rack/"
  },
  {
    "id": 9,
    "name": "Img_Comidas_Rack_Paquete_11.png",
    "path": "/assets/img/ImgComidas/Rack/Incorrectos_Rack/"
  },
  {
    "id": 10,
    "name": "Img_Comidas_Rack_Paquete_03.png",
    "path": "/assets/img/ImgComidas/Rack/"
  },
  {
    "id": 11,
    "name": "Img_Comidas_Rack_Paquete_12.png",
    "path": "/assets/img/ImgComidas/Rack/Incorrectos_Rack/"
  },
  {
    "id": 12,
    "name": "Img_Comidas_Rack_Paquete_13.png",
    "path": "/assets/img/ImgComidas/Rack/Incorrectos_Rack/"
  },
  {
    "id": 13,
    "name": "Img_Comidas_Rack_Paquete_14.png",
    "path": "/assets/img/ImgComidas/Rack/Incorrectos_Rack/"
  },
  {
    "id": 15,
    "name": "Img_Comidas_Rack_Paquete_04.png",
    "path": "/assets/img/ImgComidas/Rack/"
  },
  {
    "id": 16,
    "name": "Img_Comidas_Rack_Paquete_16.png",
    "path": "/assets/img/ImgComidas/Rack/Incorrectos_Rack/"
  },
  {
    "id": 17,
    "name": "Img_Comidas_Rack_Paquete_17.png",
    "path": "/assets/img/ImgComidas/Rack/Incorrectos_Rack/"
  },
  {
    "id": 18,
    "name": "Img_Comidas_Rack_Paquete_18.png",
    "path": "/assets/img/ImgComidas/Rack/Incorrectos_Rack/"
  },
  {
    "id": 19,
    "name": "Img_Comidas_Rack_Paquete_19.png",
    "path": "/assets/img/ImgComidas/Rack/Incorrectos_Rack/"
  }];

  export const comidasRackComunicacionPackages = [{
    "id": 1,
    "name": "Img_Comunicacion_Comidas_Rack_01.png",
    "path": "/assets/img/ImgComidas/Rack/Comunicacion_Comidas_Rack/Incorrecto/"
  },
  {
    "id": 2,
    "name": "Img_Comunicacion_Comidas_Rack_02.png",
    "path": "/assets/img/ImgComidas/Rack/Comunicacion_Comidas_Rack/"
  },
  {
    "id": 3,
    "name": "Img_Comunicacion_Comidas_Rack_05.png",
    "path": "/assets/img/ImgComidas/Rack/Comunicacion_Comidas_Rack/Incorrecto/"
  },
  {
    "id": 4,
    "name": "Img_Comunicacion_Comidas_Rack_06.png",
    "path": "/assets/img/ImgComidas/Rack/Comunicacion_Comidas_Rack/Incorrecto/"
  },
  {
    "id": 5,
    "name": "Img_Comunicacion_Comidas_Rack_03.png",
    "path": "/assets/img/ImgComidas/Rack/Comunicacion_Comidas_Rack/"
  },
  {
    "id": 6,
    "name": "Img_Comunicacion_Comidas_Rack_07.png",
    "path": "/assets/img/ImgComidas/Rack/Comunicacion_Comidas_Rack/Incorrecto/"
  },
  {
    "id": 7,
    "name": "Img_Comunicacion_Comidas_Rack_04.png",
    "path": "/assets/img/ImgComidas/Rack/Comunicacion_Comidas_Rack/Incorrecto/"
  },
  {
    "id": 8,
    "name": "Img_Comunicacion_Comidas_Rack_08.png",
    "path": "/assets/img/ImgComidas/Rack/Comunicacion_Comidas_Rack/Incorrecto/"
  }
  ];

  export const comidasTortilleroPackages = [{
    "id": 1,
    "name": "Img_Comidas_Tortillero_Paquete_03.png",
    "path": "/assets/img/ImgComidas/Tortillero/Incorrecto_Tortillero/"
  },
  {
    "id": 2,
    "name": "Img_Comidas_Tortillero_Paquete_04.png",
    "path": "/assets/img/ImgComidas/Tortillero/Incorrecto_Tortillero/"
  },
  {
    "id": 3,
    "name": "Img_Comidas_Tortillero_Paquete_01.png",
    "path": "/assets/img/ImgComidas/Tortillero/"
  },
  {
    "id": 4,
    "name": "Img_Comidas_Tortillero_Paquete_05.png",
    "path": "/assets/img/ImgComidas/Tortillero/Incorrecto_Tortillero/"
  },
  {
    "id": 5,
    "name": "Img_Comidas_Tortillero_Paquete_06.png",
    "path": "/assets/img/ImgComidas/Tortillero/Incorrecto_Tortillero/"
  },
  {
    "id": 6,
    "name": "Img_Comidas_Tortillero_Paquete_07.png",
    "path": "/assets/img/ImgComidas/Tortillero/Incorrecto_Tortillero/"
  },
  {
    "id": 7,
    "name": "Img_Comidas_Tortillero_Paquete_08.png",
    "path": "/assets/img/ImgComidas/Tortillero/Incorrecto_Tortillero/"
  },
  {
    "id": 8,
    "name": "Img_Comidas_Tortillero_Paquete_09.png",
    "path": "/assets/img/ImgComidas/Tortillero/Incorrecto_Tortillero/"
  },
  {
    "id": 9,
    "name": "Img_Comidas_Tortillero_Paquete_02.png",
    "path": "/assets/img/ImgComidas/Tortillero/"
  },
  {
    "id": 10,
    "name": "Img_Comidas_Tortillero_Paquete_10.png",
    "path": "/assets/img/ImgComidas/Tortillero/Incorrecto_Tortillero/"
  }];

  
export const comidasTortilleroComunicacionPackages = [{
    "id": 1,
    "name": "Img_Comunicacion_Tortillero_03.png",
    "path": "/assets/img/ImgComidas/Tortillero/Comunicacion_Comidas_Tortillero/Incorrecto/"
  },
  {
    "id": 2,
    "name": "Img_Comunicacion_Tortillero_04.png",
    "path": "/assets/img/ImgComidas/Tortillero/Comunicacion_Comidas_Tortillero/Incorrecto/"
  },
  {
    "id": 3,
    "name": "Img_Comunicacion_Tortillero_01.png",
    "path": "/assets/img/ImgComidas/Tortillero/Comunicacion_Comidas_Tortillero/"
  },
  {
    "id": 4,
    "name": "Img_Comunicacion_Tortillero_02.png",
    "path": "/assets/img/ImgComidas/Tortillero/Comunicacion_Comidas_Tortillero/"
  },
  {
    "id": 5,
    "name": "Img_Comunicacion_Tortillero_05.png",
    "path": "/assets/img/ImgComidas/Tortillero/Comunicacion_Comidas_Tortillero/Incorrecto/"
  }
  ];


  /**END COMIDAS */
  export const saboresCoolerSidralPackages = [{
    "id": 1,
    "name": "Img_SaboresSidral_Cooler_Paquete_05.png",
    "path": "/assets/img/ImgSabores/Cooler/Sidral/Incorrecto/"
  },
  {
    "id": 2,
    "name": "Img_SaboresSidral_Cooler_Paquete_03.png",
    "path": "/assets/img/ImgSabores/Cooler/Sidral/"
  },
  {
    "id": 3,
    "name": "Img_SaboresSidral_Cooler_Paquete_01.png",
    "path": "/assets/img/ImgSabores/Cooler/Sidral/"
  },
  {
    "id": 4,
    "name": "Img_SaboresSidral_Cooler_Paquete_07.png",
    "path": "/assets/img/ImgSabores/Cooler/Sidral/Incorrecto/"
  },
  {
    "id": 5,
    "name": "Img_SaboresSidral_Cooler_Paquete_04.png",
    "path": "/assets/img/ImgSabores/Cooler/Sidral/"
  },
  {
    "id": 6,
    "name": "Img_SaboresSidral_Cooler_Paquete_06.png",
    "path": "/assets/img/ImgSabores/Cooler/Sidral/Incorrecto/"
  },
  {
    "id": 7,
    "name": "Img_SaboresSidral_Cooler_Paquete_02.png",
    "path": "/assets/img/ImgSabores/Cooler/Sidral/"
  }];

  export const saboresCooleComunicacionrSidralPackages = [{
    "id": 1,
    "name": "Img_Comunicacion_Sabores_Sidral_01.png",
    "path": "/assets/img/ImgSabores/Cooler/Sidral/Comunicacion_Sidral/"
  },
  {
    "id": 2,
    "name": "Img_Comunicacion_Sabores_Sidral_02.png",
    "path": "/assets/img/ImgSabores/Cooler/Sidral/Comunicacion_Sidral/"
  },
  {
    "id": 3,
    "name": "Img_Comunicacion_Sabores_Sidral_05.png",
    "path": "/assets/img/ImgSabores/Cooler/Sidral/Comunicacion_Sidral/Incorrecto/"
  },
  {
    "id": 4,
    "name": "Img_Comunicacion_Sabores_Sidral_03.png",
    "path": "/assets/img/ImgSabores/Cooler/Sidral/Comunicacion_Sidral/"
  },
  {
    "id": 5,
    "name": "Img_Comunicacion_Sabores_Sidral_06.png",
    "path": "/assets/img/ImgSabores/Cooler/Sidral/Comunicacion_Sidral/Incorrecto/"
  },
  {
    "id": 6,
    "name": "Img_Comunicacion_Sabores_Sidral_07.png",
    "path": "/assets/img/ImgSabores/Cooler/Sidral/Comunicacion_Sidral/Incorrecto/"
  },
  {
    "id": 7,
    "name": "Img_Comunicacion_Sabores_Sidral_04.png",
    "path": "/assets/img/ImgSabores/Cooler/Sidral/Comunicacion_Sidral/"
  }];

  export const saboresCoolerFrescaPackages = [{
    "id": 1,
    "name": "Img_SaboresFresca_Cooler_Paquete_07.png",
    "path": "/assets/img/ImgSabores/Cooler/Fresca/Incorrecto/"
  },
  {
    "id": 2,
    "name": "Img_SaboresFresca_Cooler_Paquete_04.png",
    "path": "/assets/img/ImgSabores/Cooler/Fresca/"
  },
  {
    "id": 3,
    "name": "Img_SaboresFresca_Cooler_Paquete_05.png",
    "path": "/assets/img/ImgSabores/Cooler/Fresca/Incorrecto/"
  },
  {
    "id": 4,
    "name": "Img_SaboresFresca_Cooler_Paquete_01.png",
    "path": "/assets/img/ImgSabores/Cooler/Fresca/"
  },
  {
    "id": 5,
    "name": "Img_SaboresFresca_Cooler_Paquete_03.png",
    "path": "/assets/img/ImgSabores/Cooler/Fresca/"
  },
  {
    "id": 6,
    "name": "Img_SaboresFresca_Cooler_Paquete_06.png",
    "path": "/assets/img/ImgSabores/Cooler/Fresca/Incorrecto/"
  },
  {
    "id": 7,
    "name": "Img_SaboresFresca_Cooler_Paquete_02.png",
    "path": "/assets/img/ImgSabores/Cooler/Fresca/"
  }];

  export const saboresCoolerComunicacionFrescaPackages = [{
    "id": 1,
    "name": "Img_Comunicacion_Sabores_Fresca_03.png",
    "path": "/assets/img/ImgSabores/Cooler/Fresca/Comunicacion_Fresca/"
  },
  {
    "id": 2,
    "name": "Img_Comunicacion_Sabores_Fresca_05.png",
    "path": "/assets/img/ImgSabores/Cooler/Fresca/Comunicacion_Fresca/Incorrecto/"
  },
  {
    "id": 3,
    "name": "Img_Comunicacion_Sabores_Fresca_02.png",
    "path": "/assets/img/ImgSabores/Cooler/Fresca/Comunicacion_Fresca/"
  },
  {
    "id": 4,
    "name": "Img_Comunicacion_Sabores_Fresca_06.png",
    "path": "/assets/img/ImgSabores/Cooler/Fresca/Comunicacion_Fresca/Incorrecto/"
  },
  {
    "id": 5,
    "name": "Img_Comunicacion_Sabores_Fresca_04.png",
    "path": "/assets/img/ImgSabores/Cooler/Fresca/Comunicacion_Fresca/"
  },
  {
    "id": 6,
    "name": "Img_Comunicacion_Sabores_Fresca_01.png",
    "path": "/assets/img/ImgSabores/Cooler/Fresca/Comunicacion_Fresca/"
  },
  {
    "id": 7,
    "name": "Img_Comunicacion_Sabores_Fresca_07.png",
    "path": "/assets/img/ImgSabores/Cooler/Fresca/Comunicacion_Fresca/Incorrecto/"
  }];


  /**BEGIN SABORES */

  /**BEGIN DESAYUNO */
 export  const desayunoCoolerPackages = [{
    "id": 1,
    "name": "Img_Desayuno_Cooler_Paquetes_01.png",
    "path": "/assets/img/ImgDesayuno/Cooler/"
  },
  {
    "id": 2,
    "name": "Img_Desayuno_Cooler_Paquetes_05.png",
    "path": "/assets/img/ImgDesayuno/Cooler/"
  },
  {
    "id": 3,
    "name": "Img_Desayuno_Cooler_Paquetes_06.png",
    "path": "/assets/img/ImgDesayuno/Cooler/Incorrecto/"
  },
  {
    "id": 4,
    "name": "Img_Desayuno_Cooler_Paquetes_07.png",
    "path": "/assets/img/ImgDesayuno/Cooler/Incorrecto/"
  },
  {
    "id": 5,
    "name": "Img_Desayuno_Cooler_Paquetes_02.png",
    "path": "/assets/img/ImgDesayuno/Cooler/"
  },
  {
    "id": 6,
    "name": "Img_Desayuno_Cooler_Paquetes_08.png",
    "path": "/assets/img/ImgDesayuno/Cooler/Incorrecto/"
  },
  {
    "id": 7,
    "name": "Img_Desayuno_Cooler_Paquetes_09.png",
    "path": "/assets/img/ImgDesayuno/Cooler/Incorrecto/"
  },
  {
    "id": 8,
    "name": "Img_Desayuno_Cooler_Paquetes_10.png",
    "path": "/assets/img/ImgDesayuno/Cooler/Incorrecto/"
  },
  {
    "id": 9,
    "name": "Img_Desayuno_Cooler_Paquetes_03.png",
    "path": "/assets/img/ImgDesayuno/Cooler/"
  },
  {
    "id": 10,
    "name": "Img_Desayuno_Cooler_Paquetes_11.png",
    "path": "/assets/img/ImgDesayuno/Cooler/Incorrecto/"
  },
  {
    "id": 11,
    "name": "Img_Desayuno_Cooler_Paquetes_04.png",
    "path": "/assets/img/ImgDesayuno/Cooler/"
  }];

  export const desayunoCoolerComunicacionPackages = [ 
    {
    "id": 1,
    "name": "Img_Comunicacion_Cooler_Desayuno_02.png",
    "path": "/assets/img/ImgDesayuno/Cooler/Comunicacion_Desayuno_Cooler/Incorrecto/"
    },
    {
    "id": 2,
    "name": "Img_Comunicacion_Cooler_Desayuno_03.png",
    "path": "/assets/img/ImgDesayuno/Cooler/Comunicacion_Desayuno_Cooler/Incorrecto/"
    },
    {
    "id": 3,
    "name": "Img_Comunicacion_Cooler_Desayuno_01.png",
    "path": "/assets/img/ImgDesayuno/Cooler/Comunicacion_Desayuno_Cooler/"
    },
    {
    "id": 4,
    "name": "Img_Comunicacion_Cooler_Desayuno_04.png",
    "path": "/assets/img/ImgDesayuno/Cooler/Comunicacion_Desayuno_Cooler/"
    },
    {
    "id": 5,
    "name": "Img_Comunicacion_Cooler_Desayuno_05.png",
    "path": "/assets/img/ImgDesayuno/Cooler/Comunicacion_Desayuno_Cooler/"
    },
    {
    "id": 6,
    "name": "Img_Comunicacion_Cooler_Desayuno_06.png",
    "path": "/assets/img/ImgDesayuno/Cooler/Comunicacion_Desayuno_Cooler/Incorrecto/"
    }
];

export const desayunoRackPackages = [{
    "id": 1,
    "name": "Img_Desayuno_Rack_Paquete_05.png",
    "path": "/assets/img/ImgDesayuno/Rack/Incorrecto/"
  },
  {
    "id": 2,
    "name": "Img_Desayuno_Rack_Paquete_01.png",
    "path": "/assets/img/ImgDesayuno/Rack/"
  },
  {
    "id": 3,
    "name": "Img_Desayuno_Rack_Paquete_02.png",
    "path": "/assets/img/ImgDesayuno/Rack/"
  },
  {
    "id": 4,
    "name": "Img_Desayuno_Rack_Paquete_06.png",
    "path": "/assets/img/ImgDesayuno/Rack/Incorrecto/"
  },
  {
    "id": 5,
    "name": "Img_Desayuno_Rack_Paquete_07.png",
    "path": "/assets/img/ImgDesayuno/Rack/Incorrecto/"
  },
  {
    "id": 6,
    "name": "Img_Desayuno_Rack_Paquete_03.png",
    "path": "/assets/img/ImgDesayuno/Rack/"
  },
  {
    "id": 7,
    "name": "Img_Desayuno_Rack_Paquete_08.png",
    "path": "/assets/img/ImgDesayuno/Rack/Incorrecto/"
  },
  {
    "id": 8,
    "name": "Img_Desayuno_Rack_Paquete_09.png",
    "path": "/assets/img/ImgDesayuno/Rack/Incorrecto/"
  },
  {
    "id": 9,
    "name": "Img_Desayuno_Rack_Paquete_04.png",
    "path": "/assets/img/ImgDesayuno/Rack/"
  },
  {
    "id": 10,
    "name": "Img_Desayuno_Rack_Paquete_10.png",
    "path": "/assets/img/ImgDesayuno/Rack/Incorrecto/"
  }];

  export const desayunoRackComunicacionPackages = [{
    "id": 1,
    "name": "Img_Comunicacion_Rack_Desayuno_03.png",
    "path": "/assets/img/ImgDesayuno/Rack/Comunicacion_Desayuno_Rack/Incorrecto/"
  },
  {
    "id": 2,
    "name": "Img_Comunicacion_Rack_Desayuno_02.png",
    "path": "/assets/img/ImgDesayuno/Rack/Comunicacion_Desayuno_Rack/"
  },
  {
    "id": 3,
    "name": "Img_Comunicacion_Rack_Desayuno_04.png",
    "path": "/assets/img/ImgDesayuno/Rack/Comunicacion_Desayuno_Rack/Incorrecto/"
  },
  {
    "id": 4,
    "name": "Img_Comunicacion_Rack_Desayuno_05.png",
    "path": "/assets/img/ImgDesayuno/Rack/Comunicacion_Desayuno_Rack/"
  },
  {
    "id": 5,
    "name": "Img_Comunicacion_Rack_Desayuno_01.png",
    "path": "/assets/img/ImgDesayuno/Rack/Comunicacion_Desayuno_Rack/"
  },
  ];
  /**END DESAYUNO */

  /**BEGIN HIDRATACION */
export const hidratacionCoolerPackages = [{
    "id": 1,
    "name": "Img_Hidratacion_Cooler_Paquete_01.png",
    "path": "/assets/img/ImgHidratacion/Cooler/"
  },
  {
    "id": 2,
    "name": "Img_Hidratacion_Cooler_Paquete_05.png",
    "path": "/assets/img/ImgHidratacion/Cooler/Incorrectos/"
  },
  {
    "id": 3,
    "name": "Img_Hidratacion_Cooler_Paquete_06.png",
    "path": "/assets/img/ImgHidratacion/Cooler/Incorrectos/"
  },
  {
    "id": 4,
    "name": "Img_Hidratacion_Cooler_Paquete_07.png",
    "path": "/assets/img/ImgHidratacion/Cooler/Incorrectos/"
  },
  {
    "id": 5,
    "name": "Img_Hidratacion_Cooler_Paquete_02.png",
    "path": "/assets/img/ImgHidratacion/Cooler/"
  },
  {
    "id": 6,
    "name": "Img_Hidratacion_Cooler_Paquete_08.png",
    "path": "/assets/img/ImgHidratacion/Cooler/Incorrectos/"
  },
  {
    "id": 7,
    "name": "Img_Hidratacion_Cooler_Paquete_09.png",
    "path": "/assets/img/ImgHidratacion/Cooler/Incorrectos/"
  },
  {
    "id": 8,
    "name": "Img_Hidratacion_Cooler_Paquete_03.png",
    "path": "/assets/img/ImgHidratacion/Cooler/"
  },
  {
    "id": 9,
    "name": "Img_Hidratacion_Cooler_Paquete_10.png",
    "path": "/assets/img/ImgHidratacion/Cooler/Incorrectos/"
  },
  {
    "id": 10,
    "name": "Img_Hidratacion_Cooler_Paquete_11.png",
    "path": "/assets/img/ImgHidratacion/Cooler/Incorrectos/"
  },
  {
    "id": 11,
    "name": "Img_Hidratacion_Cooler_Paquete_12.png",
    "path": "/assets/img/ImgHidratacion/Cooler/Incorrectos/"
  },
  {
    "id": 12,
    "name": "Img_Hidratacion_Cooler_Paquete_13.png",
    "path": "/assets/img/ImgHidratacion/Cooler/Incorrectos/"
  },
  {
    "id": 13,
    "name": "Img_Hidratacion_Cooler_Paquete_04.png",
    "path": "/assets/img/ImgHidratacion/Cooler/"
  }];

  export const hidratacionCoolerComunicacionPackages = [ 
    {
    "id": 1,
    "name": "Img_Comunicacion_Hidratacion_Cooler_01.png",
    "path": "/assets/img/ImgHidratacion/Cooler/Comunicacion_Hidratacion_Cooler/"
    },
    {
    "id": 2,
    "name": "Img_Comunicacion_Hidratacion_Cooler_05.png",
    "path": "/assets/img/ImgHidratacion/Cooler/Comunicacion_Hidratacion_Cooler/Incorrecto/"
    },
    {
    "id": 3,
    "name": "Img_Comunicacion_Hidratacion_Cooler_02.png",
    "path": "/assets/img/ImgHidratacion/Cooler/Comunicacion_Hidratacion_Cooler/Incorrecto/"
    },
    {
      "id": 4,
      "name": "Img_Comunicacion_Hidratacion_Cooler_03.png",
      "path": "/assets/img/ImgHidratacion/Cooler/Comunicacion_Hidratacion_Cooler/"
    },
    {
    "id": 7,
    "name": "Img_Comunicacion_Hidratacion_Cooler_04.png",
    "path": "/assets/img/ImgHidratacion/Cooler/Comunicacion_Hidratacion_Cooler/Incorrecto/"
    },
    {
    "id": 5,
    "name": "Img_Comunicacion_Hidratacion_Cooler_06.png",
    "path": "/assets/img/ImgHidratacion/Cooler/Comunicacion_Hidratacion_Cooler/Incorrecto/"
    },
    {
    "id": 6,
    "name": "Img_Comunicacion_Hidratacion_Cooler_07.png",
    "path": "/assets/img/ImgHidratacion/Cooler/Comunicacion_Hidratacion_Cooler/Incorrecto/"
    },
    {
    "id": 8,
    "name": "Img_Comunicacion_Hidratacion_Cooler_08.png",
    "path": "/assets/img/ImgHidratacion/Cooler/Comunicacion_Hidratacion_Cooler/Incorrecto/"
    }
];

export const hidratacionRackPackages = [{
    "id": 1,
    "name": "Img_Hidratacion_Rack_Paquete_05.png",
    "path": "/assets/img/ImgHidratacion/Rack/Incorrectos/"
  },
  {
    "id": 2,
    "name": "Img_Hidratacion_Rack_Paquete_06.png",
    "path": "/assets/img/ImgHidratacion/Rack/Incorrectos/"
  },
  {
    "id": 3,
    "name": "Img_Hidratacion_Rack_Paquete_01.png",
    "path": "/assets/img/ImgHidratacion/Rack/"
  },
  {
    "id": 4,
    "name": "Img_Hidratacion_Rack_Paquete_07.png",
    "path": "/assets/img/ImgHidratacion/Rack/Incorrectos/"
  },
  {
    "id": 5,
    "name": "Img_Hidratacion_Rack_Paquete_08.png",
    "path": "/assets/img/ImgHidratacion/Rack/Incorrectos/"
  },
  {
    "id": 6,
    "name": "Img_Hidratacion_Rack_Paquete_09.png",
    "path": "/assets/img/ImgHidratacion/Rack/Incorrectos/"
  },
  {
    "id": 7,
    "name": "Img_Hidratacion_Rack_Paquete_02.png",
    "path": "/assets/img/ImgHidratacion/Rack/"
  },
  {
    "id": 8,
    "name": "Img_Hidratacion_Rack_Paquete_10.png",
    "path": "/assets/img/ImgHidratacion/Rack/Incorrectos/"
  },
  {
    "id": 9,
    "name": "Img_Hidratacion_Rack_Paquete_11.png",
    "path": "/assets/img/ImgHidratacion/Rack/Incorrectos/"
  },
  {
    "id": 10,
    "name": "Img_Hidratacion_Rack_Paquete_03.png",
    "path": "/assets/img/ImgHidratacion/Rack/"
  },
  {
    "id": 11,
    "name": "Img_Hidratacion_Rack_Paquete_04.png",
    "path": "/assets/img/ImgHidratacion/Rack/"
  }];

  export const hidratacionRackComunicacionPackages = [
    {
      "id": 2,
      "name": "Img_Comunicacion_Hidratacion_Rack_06.png",
      "path": "/assets/img/ImgHidratacion/Rack/Comunicacion_Hidratacion_Rack/Incorrectos/"
    },
    {
      "id": 3,
      "name": "Img_Comunicacion_Hidratacion_Rack_07.png",
      "path": "/assets/img/ImgHidratacion/Rack/Comunicacion_Hidratacion_Rack/Incorrectos/"
    },
    {
      "id": 5,
      "name": "Img_Comunicacion_Hidratacion_Rack_03.png",
      "path": "/assets/img/ImgHidratacion/Rack/Comunicacion_Hidratacion_Rack/Incorrectos/"
    },
    {
      "id": 1,
      "name": "Img_Comunicacion_Hidratacion_Rack_01.png",
      "path": "/assets/img/ImgHidratacion/Rack/Comunicacion_Hidratacion_Rack/Incorrectos/"
    },
    
    {
      "id": 7,
      "name": "Img_Comunicacion_Hidratacion_Rack_08.png",
      "path": "/assets/img/ImgHidratacion/Rack/Comunicacion_Hidratacion_Rack/Incorrectos/"
    },
    {
      "id": 6,
      "name": "Img_Comunicacion_Hidratacion_Rack_04.png",
      "path": "/assets/img/ImgHidratacion/Rack/Comunicacion_Hidratacion_Rack/"
    },
    {
      "id": 4,
      "name": "Img_Comunicacion_Hidratacion_Rack_02.png",
      "path": "/assets/img/ImgHidratacion/Rack/Comunicacion_Hidratacion_Rack/Incorrectos/"
    },
    {
      "id": 8,
      "name": "Img_Comunicacion_Hidratacion_Rack_05.png",
      "path": "/assets/img/ImgHidratacion/Rack/Comunicacion_Hidratacion_Rack/"
    },
    {
      "id": 9,
      "name": "Img_Comunicacion_Hidratacion_Rack_09.png",
      "path": "/assets/img/ImgHidratacion/Rack/Comunicacion_Hidratacion_Rack/Incorrectos/"
    },
    ];

    export const misMomentosCoolerPackages = [{
        "id": 1,
        "name": "Img_MisMomentos_Cooler_Paquete_06.png",
        "path": "/assets/img/ImgMisMomentos/Cooler/Incorrectos_Cooler_MM/"
      },
      {
        "id": 2,
        "name": "Img_MisMomentos_Cooler_Paquete_01.png",
        "path": "/assets/img/ImgMisMomentos/Cooler/"
      },
      {
        "id": 3,
        "name": "Img_MisMomentos_Cooler_Paquete_07.png",
        "path": "/assets/img/ImgMisMomentos/Cooler/Incorrectos_Cooler_MM/"
      },
      {
        "id": 4,
        "name": "Img_MisMomentos_Cooler_Paquete_02.png",
        "path": "/assets/img/ImgMisMomentos/Cooler/"
      },
      {
        "id": 5,
        "name": "Img_MisMomentos_Cooler_Paquete_03.png",
        "path": "/assets/img/ImgMisMomentos/Cooler/"
      },
      {
        "id": 6,
        "name": "Img_MisMomentos_Cooler_Paquete_08.png",
        "path": "/assets/img/ImgMisMomentos/Cooler/Incorrectos_Cooler_MM/"
      },
      {
        "id": 7,
        "name": "Img_MisMomentos_Cooler_Paquete_09.png",
        "path": "/assets/img/ImgMisMomentos/Cooler/Incorrectos_Cooler_MM/"
      },
      {
        "id": 8,
        "name": "Img_MisMomentos_Cooler_Paquete_10.png",
        "path": "/assets/img/ImgMisMomentos/Cooler/Incorrectos_Cooler_MM/"
      },
      {
        "id": 9,
        "name": "Img_MisMomentos_Cooler_Paquete_11.png",
        "path": "/assets/img/ImgMisMomentos/Cooler/Incorrectos_Cooler_MM/"
      },
      {
        "id": 10,
        "name": "Img_MisMomentos_Cooler_Paquete_12.png",
        "path": "/assets/img/ImgMisMomentos/Cooler/Incorrectos_Cooler_MM/"
      },
      {
        "id": 11,
        "name": "Img_MisMomentos_Cooler_Paquete_04.png",
        "path": "/assets/img/ImgMisMomentos/Cooler/"
      },
      {
        "id": 12,
        "name": "Img_MisMomentos_Cooler_Paquete_05.png",
        "path": "/assets/img/ImgMisMomentos/Cooler/"
      },
      {
        "id": 13,
        "name": "Img_MisMomentos_Cooler_Paquete_13.png",
        "path": "/assets/img/ImgMisMomentos/Cooler/Incorrectos_Cooler_MM/"
      }];

      export const misMomentosCoolerComunicacionPackages = [ 
        {
            "id": 1,
            "name": "Img_Comunicacion_MisMomentos_03.png",
            "path": "/assets/img/ImgMisMomentos/Cooler/Comunicacion_Mis_Momentos/Incorrecto/"
          },
          {
            "id": 2,
            "name": "Img_Comunicacion_MisMomentos_04.png",
            "path": "/assets/img/ImgMisMomentos/Cooler/Comunicacion_Mis_Momentos/Incorrecto/"
          },
          {
            "id": 3,
            "name": "Img_Comunicacion_MisMomentos_05.png",
            "path": "/assets/img/ImgMisMomentos/Cooler/Comunicacion_Mis_Momentos/Incorrecto/"
          },{
        "id": 4,
        "name": "Img_Comunicacion_MisMomentos_01.png",
        "path": "/assets/img/ImgMisMomentos/Cooler/Comunicacion_Mis_Momentos/"
      },
      {
        "id": 5,
        "name": "Img_Comunicacion_MisMomentos_06.png",
        "path": "/assets/img/ImgMisMomentos/Cooler/Comunicacion_Mis_Momentos/Incorrecto/"
      },
      {
        "id": 6,
        "name": "Img_Comunicacion_MisMomentos_02.png",
        "path": "/assets/img/ImgMisMomentos/Cooler/Comunicacion_Mis_Momentos/Incorrecto/"
      }];

      export const misMomentosEnfriadorPackages = [{
        "id": 1,
        "name": "Img_MisMomentos_Cooler_3P_Paquete_01.png",
        "path": "/assets/img/ImgMisMomentos/Enfriador/"
      },
      {
        "id": 2,
        "name": "Img_MisMomentos_Cooler_3P_Paquete_02.png",
        "path": "/assets/img/ImgMisMomentos/Enfriador/"
      },
      {
        "id": 3,
        "name": "Img_MisMomentos_Cooler_3P_Paquete_03.png",
        "path": "/assets/img/ImgMisMomentos/Enfriador/"
      },
      {
        "id": 4,
        "name": "Img_MisMomentos_Cooler_3P_Paquete_04.png",
        "path": "/assets/img/ImgMisMomentos/Enfriador/"
      },
      {
        "id": 5,
        "name": "Img_MisMomentos_Cooler_3P_Paquete_05.png",
        "path": "/assets/img/ImgMisMomentos/Enfriador/"
      },
      {
        "id": 6,
        "name": "Img_MisMomentos_Cooler_3P_Paquete_06.png",
        "path": "/assets/img/ImgMisMomentos/Enfriador/Incorrectos/"
      },
      {
        "id": 7,
        "name": "Img_MisMomentos_Cooler_3P_Paquete_07.png",
        "path": "/assets/img/ImgMisMomentos/Enfriador/Incorrectos/"
      }];

  /** END HIDRATACION */


  /**BEGIN ANAQUELES*/
  export const comidasAnaquelesPackages = [{
    "id": 1,
    "name": "Img_Comidas_Anaquel_Paquete_01.png",
    "path": "/assets/img/ImgComidas/Anaqueles/Incorrectos/"
  },
  {
    "id": 2,
    "name": "Img_Comidas_Anaquel_Paquete_02.png",
    "path": "/assets/img/ImgComidas/Anaqueles/Incorrectos/"
  },
  {
    "id": 3,
    "name": "Img_Comidas_Anaquel_Paquete_03.png",
    "path": "/assets/img/ImgComidas/Anaqueles/"
  }];

  export const comidasAnaquelesComunicacionPackages = [{
    "id": 1,
    "name": "Img_Comunicacion_Comidas_Anaquel_Paquete_01.png",
    "path": "/assets/img/ImgComidas/Anaqueles/Incorrectos/"
  },
  {
    "id": 2,
    "name": "Img_Comunicacion_Comidas_Anaquel_Paquete_02.png",
    "path": "/assets/img/ImgComidas/Anaqueles/"
  },
  {
    "id": 3,
    "name": "Img_Comunicacion_Comidas_Anaquel_Paquete_03.png",
    "path": "/assets/img/ImgComidas/Anaqueles/Incorrectos/"
  }];

  export const hidratacionAnaquelesPackages = [{
    "id": 1,
    "name": "Img_Hidratacion_Anaquel_Paquete_01.png",
    "path": "/assets/img/ImgHidratacion/Anaqueles/Incorrectos/"
  },
  {
    "id": 2,
    "name": "Img_Hidratacion_Anaquel_Paquete_02.png",
    "path": "/assets/img/ImgHidratacion/Anaqueles/"
  },
  {
    "id": 3,
    "name": "Img_Hidratacion_Anaquel_Paquete_03.png",
    "path": "/assets/img/ImgHidratacion/Anaqueles/Incorrectos/"
  }];

  export const hidratacionAnaquelesComunicacionPackages = [{
    "id": 1,
    "name": "Img_Comunicacion_Hidratacion_Anaquel_Paquete_01.png",
    "path": "/assets/img/ImgHidratacion/Anaqueles/Incorrectos/"
  },
  {
    "id": 2,
    "name": "Img_Comunicacion_Hidratacion_Anaquel_Paquete_02.png",
    "path": "/assets/img/ImgHidratacion/Anaqueles/"
  },
  {
    "id": 3,
    "name": "Img_Comunicacion_Hidratacion_Anaquel_Paquete_03.png",
    "path": "/assets/img/ImgHidratacion/Anaqueles/Incorrectos/"
  }];

  export const desayunoAnaquelesPackages = [{
    "id": 1,
    "name": "Img_Desayuno_Anaquel_Paquete_01.png",
    "path": "/assets/img/ImgDesayuno/Anaqueles/"
  },
  {
    "id": 2,
    "name": "Img_Desayuno_Anaquel_Paquete_02.png",
    "path": "/assets/img/ImgDesayuno/Anaqueles/Incorrectos/"
  },
  {
    "id": 3,
    "name": "Img_Desayuno_Anaquel_Paquete_03.png",
    "path": "/assets/img/ImgDesayuno/Anaqueles/Incorrectos/"
  }];

  export const desayunoAnaquelesComunicacionPackages = [{
    "id": 1,
    "name": "Img_Comunicacion_Desayuno_Anaquel_Paquete_01.png",
    "path": "/assets/img/ImgDesayuno/Anaqueles/Incorrectos/"
  },
  {
    "id": 2,
    "name": "Img_Comunicacion_Desayuno_Anaquel_Paquete_02.png",
    "path": "/assets/img/ImgDesayuno/Anaqueles/"
  },
  {
    "id": 3,
    "name": "Img_Comunicacion_Desayuno_Anaquel_Paquete_03.png",
    "path": "/assets/img/ImgDesayuno/Anaqueles/Incorrectos/"
  }];
  /**END ANAQUELEs */