import React, { useState } from 'react';
import './ComidasInicio.css';
import {AudioButton} from './AudioButton'
import { useNavigate } from 'react-router-dom';

const ComidasPInicio = () => {
    const pathAudio = '/assets/Audios/comidas/comidas_presentacion.mp3';
    const navigate = useNavigate();
    const [shouldStop, setShouldStop] = useState(false)
    

    const onClick = () => {
        setShouldStop(true);  // Actualiza el estado para detener el audio

        // Usar un pequeño retraso para asegurarse de que el audio se detiene antes de la navegación
        setTimeout(() => {
          navigate('/comidasCooler');
        }, 100); // Puedes ajustar este tiempo si es necesario
    }

    return (
        <div className='container'>
            <div className="row mt-3">
                <div className='btn-audio-comidas'>
                    <AudioButton pathAudio={pathAudio} shouldStop={shouldStop} />
                </div>
            </div>
            <div className="row">
                <div className='generalContainderComidas'>
                    <div className="containerInicioComidas">
                        <div className="image-containerComidas">
                            
                        </div>
                        <div className="buttons-containerComidas">
                            <div className="top-buttonComidas"></div>
                            <button className="bottom-buttonComidas" onClick={onClick}></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ComidasPInicio;
