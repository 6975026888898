import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'auth',
    initialState:{
        // status: 'checking',
        status: 'not-authenticated',
        // status: 'authenticated',
        id: null,
        employeeNumber: null,
        errorMessage: null,
        positionId: null,
        name: '',
        middleName: '',
        lastName: '',
        zoneId: '',
        isRegister: false,
        lastPath: ''
    },
    reducers:{
        login: (state, { payload }) =>{
            state.status = 'authenticated';
            state.id = payload.id;
            state.employeeNumber = payload.employeeNumber;
            state.name = payload.name;
            state.middleName = payload.middleName;
            state.lastName = payload.lastName;
            state.zoneId = payload.zoneId;
            state.positionId = payload.positionId;
            state.errorMessage = null;
            state.isRegister = false;
            state.lastPath = payload.lastPath

        },
        logout: (state, { payload }) => {
            state.status = 'not-authenticated';
            state.id = null;
            state.employeeNumber = null;
            state.name = null;
            state.middleName = null;
            state.lastName = null;
            state.zoneId = null;
            state.positionId = null;
            state.errorMessage = payload;
            state.isRegister = false;
        },
        checkingCredencials: (state) => {
            state.status = 'checking'
        },
        register: (state) => {
            state.status = 'not-authenticated';
            state.isRegister = true;
        },
        changeStatusRegister: (state) => {
            state.status = 'not-authenticated';
            state.isRegister = false;
            state.errorMessage = null
        }
    }
});

export const { login, logout, register, checkingCredencials, changeStatusRegister } = authSlice.actions;
